import React, {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import config from '../../../../config'
import { Button} from 'react-bootstrap';
import axios,{get,post,patch} from 'axios';
import {FaPlus,FaTimes} from 'react-icons/fa'
// import JsFileDownloader from 'js-file-downloader';
import ReactPlayer from 'react-player'
import {TextField, Dialog, DialogActions,DialogContent, DialogContentText,DialogTitle} from '@mui/material'
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../../Helper/Loader'

const img1 = `${process.env.PUBLIC_URL}/img/images/plus.png`;
const img2 = `${process.env.PUBLIC_URL}/img/images/video2.jpg`;

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};


const VideoUpload = (props) => {
  // let history = useHistory();
  let tokenKey = {headers:{Authorization:'Token '+sessionStorage.getItem('tokenKey')}};
  const [files, setFiles] = useState([]);
  const [selFile, setselFile] = useState(null);
  const [uploaded, setUploaded] = useState(null);
  const [thumbImg, setThumbImg] = useState([])
  const [check, setcheck] = useState(0)
  const [loader,setLoader] = useState({loading : true,overlay:false})
    const [showVideo, setShowVideo] = useState(false)
    const [fileURL, setfileURL] = useState(null)
    let toastAttr = {
      theme: "colored",
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      };
  const {getRootProps, getInputProps} = useDropzone({
    accept: {
      'video/*': [],
    },
    onDrop: acceptedFiles => {
        let imgs = thumbImg;
        acceptedFiles.map(file => imgs.push({file:URL.createObjectURL(file),id:null, name : file.name, video:acceptedFiles[0]}));
        setThumbImg(imgs);
      setUploaded(acceptedFiles && acceptedFiles[0].name);
      setselFile(acceptedFiles[0]);
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    }
  });

  const setLoading = (val) => {
    setLoader({loading:val,overlay:val})
  }

  const viewVideo = src => {
    setfileURL(src.file);
    setShowVideo(true)
    // new JsFileDownloader({ 
    //   url: src.file
    // })
    // .then(function () {
    //   // Called when download ended
    // })
  } 

  const handleClose = ()=>{
    setShowVideo(false);
    setfileURL(null)
  }


  const thumbs = thumbImg.map((src,index) => (
    <div style={thumb} className='thumbItem'  >
      <div style={thumbInner} className='thumbItem-inner' title='Open Video' onClick={()=>viewVideo(src)}>
        {/* <img
          src={img2} alt='video'
          style={img}
          // Revoke data uri after image is loaded
          onLoad={() => { URL.revokeObjectURL(src.file) }}
        /> */}
            <ReactPlayer url={src.file} playing={false} controls={true}  config={{
                    file: { 
                      attributes: { 
                        poster: img2 
                      } 
                    } 
                  }} />

        {/* <FaTimes className='clearFile' title='Remove Video' onClick={e=>clearFile(src.id,index,e)} /> */}
      </div>
      <div className='thumb-data'>
        <div>{src.name}</div>
        {!src.id ? <Button variant='primary' onClick={()=>uploadVideo(src,index)}>Upload</Button> : ''}
        <Button variant='primary' onClick={e=>clearFile(src.id,index,e)}>Remove</Button>
      </div>
    </div>
  ));

  const uploadVideo = (src,index) => {
    let data = new FormData(), thumb_img = thumbImg;
    data.append('file',src.video)
    data.append('video_name' , src.name)
    setLoading(true);
    post(config.service+'/masters/mandap-video/'+props.mandapId+'/',data,tokenKey).then(res=>{
      let imgs = [];
        res.data.map(obj=>{
            imgs.push({file:obj.video, id:obj.id,name:obj.video_name})
        })
      setThumbImg(imgs)
      setLoading(false);
      toast.success('Video uploaded successfully', toastAttr);
  }).catch(err=>{
    setLoading(false);
    toast.error('Uploading video failed', toastAttr);
    if(err.response.status === 401) window.location.href = "/"
  })
  }

  const clearFile = (id,index,e) => {
    if(id){
      setLoading(true);
      axios.delete(config.service+'/masters/mandap-video/'+props.mandapId, {
        headers: {
          Authorization:'Token '+sessionStorage.getItem('tokenKey')
        },
        data: {
          video_id: id
        }
      }).then(res=>{
        let imgs = thumbImg;
        imgs.splice(index,1);
        setThumbImg(imgs);
        setcheck(check+1)
      toast.success('Video is removed', toastAttr);
        setLoading(false);
      }).catch(err=>{
      setLoading(false);
    toast.error('Video removal failed', toastAttr);
    if(err.response.status === 401) window.location.href = "/"
      })
    }
    else{
      let imgs = thumbImg;
        imgs.splice(index,1);
        setThumbImg(imgs);
        setcheck(check+1)
    }
    e.stopPropagation()
  }
  
  useEffect(() => {
    get(config.service+'/masters/mandap-video/'+props.mandapId+'/',tokenKey).then(res=>{
        let imgs = [];
        res.data.map(obj=>{
            imgs.push({file:obj.video, id:obj.id,name:obj.video_name})
        })
      setThumbImg(imgs)
      setLoading(false)
    }).catch(err=>{
      setLoading(false)
      if(err.response.status === 401) window.location.href = "/"
    })
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach(file => URL.revokeObjectURL(file.preview));
  }, []);


  return (
    <Loader loading={loader.loading} overlay={loader.overlay}>
            <aside style={thumbsContainer} className='thumbsContainer'>
            <section className="container ">
                <div {...getRootProps({className: 'dropzone'})} 
                multiple={false}>
                    <input {...getInputProps()} />
                    {/* <img src={img1} alt='Add Video' /> */}
                    <Button variant='primary'>+ Add Video</Button>
                </div>
              </section>
            {thumbs}
            <span style={{display:'none'}}>{check}</span>
        </aside>
        {/* <Dialog open={showVideo} onClose={handleClose} maxWidth='md'>
            <DialogContent>
            <ReactPlayer url={fileURL} playing={true} controls={true} light={true} />
            </DialogContent>
        </Dialog> */}
    </Loader>
  );
}

export default VideoUpload;