import React, { useState, useEffect } from "react";
import { Col, Container, Row, Card, CardBody } from "reactstrap";
import { Button, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { post, get } from "axios";
import Select from "react-select";
import config from "../../../../config";
import Location from "./locationMap";
import CustomSwitch from "../../../Helper/CustomSwitch";
import Loader from "../../../Helper/Loader";
import { ToastContainer, toast } from "react-toastify";
import empty from "is-empty";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import moment from "moment";
import { HiRefresh } from "react-icons/hi";

const Mandapam = (props) => {
  let history = useHistory();
  let tokenKey = {
    headers: { Authorization: "Token " + sessionStorage.getItem("tokenKey") },
  };
  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  const [mandap, setMandap] = useState(props.mandap);
  const [readOnly, setReadOnly] = useState(false);
  const [classificationList, setclassificationList] = useState([]);
  const [classification, setclassification] = useState(null);
  const [cookingFuel, setcookingFuel] = useState(null);
  const [hallLevel, setHallLevel] = useState(null);
  const [diningLevel, setDiningLevel] = useState(null);
  const [ac, setAC] = useState(null);
  const [food, setFood] = useState(null);
  const [areaList, setareaList] = useState([]);
  const [area, setarea] = useState(null);
  const [areaData, setareaData] = useState([]);
  const [cityList, setcityList] = useState([]);
  const [city, setcity] = useState(null);
  const [cityData, setcityData] = useState([]);
  const [stateList, setstateList] = useState([]);
  const [state, setstate] = useState(null);
  const [typeList, settypeList] = useState([]);
  const [type, settype] = useState(null);
  const [hasAddr, sethasAddr] = useState(false);
  // const [entryTime, setentryTime] = useState(null);
  // const [exitTime, setexitTime] = useState(null);
  const [locData, setLocData] = useState(null);
  const [vendor, setVendor] = useState([]);
  const [vendorList, setvendorList] = useState([]);

  const errors = props.errors;

  const levels = [
    { value: "ground_level", label: "Ground Level" },
    { value: "level1", label: "Level 1" },
    { value: "level2", label: "Level 2" },
    { value: "level3", label: "Level 3" },
    { value: "level4", label: "Level 4" },
    { value: "level5", label: "Level 5" },
  ];
  const fuels = [
    { value: "firewood", label: "Firewood" },
    { value: "gas", label: "Gas" },
  ];
  const ACTypes = [
    { value: "ac", label: "AC" },
    { value: "non_ac", label: "Non AC" },
  ];
  const foodTypes = [
    { value: "veg", label: "Veg" },
    { value: "non_veg", label: "Non Veg" },
    { value: "both", label: "Both" },
  ];

  const onChangeTime = (value, keyName, setFn) => {
    let val = moment(value).format("HH:mm:ss");
    setFn(value);
    setMandap({
      ...mandap,
      [keyName]: val,
    });
    props.save(
      {
        [keyName]: val,
      },
      keyName
    );
  };

  const handleInputMandap = (keyName, value) => {
    setMandap({
      ...mandap,
      [keyName]: value,
    });
    props.save(
      {
        [keyName]: value,
      },
      keyName
    );
    if (keyName === "address_1" && value.length >= 5) sethasAddr(true);
    // else if(keyName === 'address_1' && value.length <5) sethasAddr(false)
  };
  const handleMandapCheckBox = (keyName, value) => {
    setMandap({
      ...mandap,
      [keyName]: value,
    });
    props.save(
      {
        [keyName]: value,
      },
      keyName
    );
  };
  const handleSelect = (keyName, setFn, e) => {
    if (keyName === "area_id") {
      if (e && e.value) {
        let selArea = areaData.filter((obj) => obj.id === e.value);
        setarea(e);
        setcity({ label: selArea[0].city_name, value: selArea[0].city_id });
        setstate({ label: selArea[0].state_name, value: selArea[0].state_id });
        setMandap({
          ...mandap,
          area_id: e.value,
          city_id: selArea[0].city_id,
          state_id: selArea[0].state_id,
        });
        props.save(
          {
            area_id: e.value,
            city_id: selArea[0].city_id,
            state_id: selArea[0].state_id,
          },
          "area_id"
        );
      } else {
        setarea(null);
        setcity(null);
        setstate(null);
        setMandap({
          ...mandap,
          area_id: null,
          city_id: null,
          state_id: null,
        });
        props.save({ area_id: null, city_id: null, state_id: null }, "area_id");
      }
    } else if (keyName === "hall_level") {
      setFn(e);
      setMandap({
        ...mandap,
        [keyName]: (e && e.value) || null,
        lift_access_to_hall:
          e && e.value === "ground_level" ? false : mandap.lift_access_to_hall,
      });
      props.save(
        {
          [keyName]: (e && e.value) || null,
          lift_access_to_hall:
            e && e.value === "ground_level"
              ? false
              : mandap.lift_access_to_hall,
        },
        keyName
      );
    } else if (keyName === "dining_level") {
      setFn(e);
      setMandap({
        ...mandap,
        [keyName]: (e && e.value) || null,
        lift_access_to_dining_hall:
          e && e.value === "ground_level"
            ? false
            : mandap.lift_access_to_dining_hall,
      });
      props.save(
        {
          [keyName]: (e && e.value) || null,
          lift_access_to_dining_hall:
            e && e.value === "ground_level"
              ? false
              : mandap.lift_access_to_dining_hall,
        },
        keyName
      );
    } else {
      setFn(e);
      setMandap({
        ...mandap,
        [keyName]: (e && e.value) || null,
      });
      props.save(
        {
          [keyName]: (e && e.value) || null,
        },
        keyName
      );
    }
  };

  const getCoordinates = (data) => {
    setMandap({
      ...mandap,
      latitude: data.lat,
      longitude: data.lng,
    });
    props.saveMap(data);
  };

  useEffect(() => {
    get(config.service + "/masters/mandap_classification/", tokenKey)
      .then((res) => {
        let classification = [];
        for (let i in res.data)
          classification.push({
            label: res.data[i].classification_name,
            value: res.data[i].id,
          });
        setclassificationList(classification);
        get(config.service + "/masters/mandap_type/", tokenKey).then((res) => {
          let type = [];
          for (let i in res.data)
            type.push({ label: res.data[i].name, value: res.data[i].id });
          settypeList(type);
          get(config.service + "/masters/area/list/", tokenKey).then((res) => {
            let area = [];
            for (let i in res.data)
              area.push({ label: res.data[i].name, value: res.data[i].id });
            setareaList(area);
            setareaData(res.data);
            get(config.service + "/masters/city/", tokenKey).then((res) => {
              let city = [];
              for (let i in res.data)
                city.push({ label: res.data[i].name, value: res.data[i].id });
              setcityList(city);
              setcityData(res.data);
              get(config.service + "/masters/state/", tokenKey).then((res) => {
                let state = [];
                for (let i in res.data)
                  state?.push({
                    label: res.data[i].name,
                    value: res.data[i].id,
                  });
                setstateList(state);
                get(config.service + "/masters/base/vendor/", tokenKey).then(
                  (res) => {
                    let vendor = [];
                    if (res?.length > 0) {
                      res?.data?.map((obj) => {
                        vendor?.push({ value: obj.id, label: obj.vendor_name });
                      });
                    }

                    setvendorList(vendor);
                    if (props.propsData && props.propsData.editMode) {
                      // props.setLoader(true)
                      get(
                        config.service +
                          "/masters/vendor_mandap/" +
                          props.propsData.id,
                        tokenKey
                      )
                        .then((res) => {
                          let classVal = classification.filter(
                            (temp) =>
                              temp.value === res.data.mandap_classification_id
                          );
                          let vendorVal = vendor.filter(
                            (temp) => temp.value === res.data.vendor_id
                          );
                          let areaVal = area.filter(
                            (temp) => temp.value === res.data.area_id
                          );
                          let cityVal = city.filter(
                            (temp) => temp.value === res.data.city_id
                          );
                          let stateVal = state.filter(
                            (temp) => temp.value === res.data.state_id
                          );
                          let typeVal = type.filter(
                            (temp) => temp.value === res.data.mandap_type_id
                          );
                          let fuelVal = fuels.filter(
                            (temp) => temp.value === res.data.cooking_fuel_used
                          );
                          let hallVal = levels.filter(
                            (temp) => temp.value === res.data.hall_level
                          );
                          let diningVal = levels.filter(
                            (temp) => temp.value === res.data.dining_level
                          );
                          let acVal = ACTypes.filter(
                            (temp) => temp.value === res.data.ac_type
                          );
                          let foodVal = foodTypes.filter(
                            (temp) => temp.value === res.data.food_type
                          );
                          setclassification(classVal[0]);
                          setVendor(vendorVal[0]);
                          setarea(areaVal[0]);
                          setcity(cityVal[0]);
                          setstate(stateVal[0]);
                          settype(typeVal[0]);
                          setcookingFuel(fuelVal[0]);
                          setHallLevel(hallVal[0]);
                          setDiningLevel(diningVal[0]);
                          setAC(acVal[0]);
                          setFood(foodVal[0]);
                          // const d = moment(new Date()).format('L'); // d = "12/12/2017"
                          // const entry_date = moment(d +' '+ res.data.entry_time).format();
                          // const exit_date = moment(d +' '+ res.data.exit_time).format();
                          // setentryTime(moment(entry_date).toDate())
                          // setexitTime(moment(exit_date).toDate())
                          //  if(res.data.status !== 'draft' && res.data.status !== 'rejected') setReadOnly(true)
                          // remove comment
                          // if(res.data.latitude && res.data.longitude) sethasAddr(false)
                          // else
                          if (res.data.address_1) {
                            sethasAddr(true);
                            setLocData({ address_1: res.data.address_1 });
                          }

                          setMandap(res.data);
                          props.save(res.data);
                          props.setLoader(false);
                        })
                        .catch((err) => {
                          props.setLoader(false);
                          toast.error("Retrieval Failed", toastAttr);
                        });
                    } else props.setLoader(false);
                  }
                );
              });
            });
          });
        });
      })
      .catch((err) => {
        if (err?.response?.status === 401) window.location.href = "/";
        props.setLoader(false);
      });
  }, []);

  const getLocation = () => {
    sethasAddr(true);
    setLocData({ address_1: mandap.address_1 });
  };

  return (
    <Row>
      <Col md={12}>
        <Loader loading={props.loading} overlay={props.overlay}>
          <fieldset class="border p-2 fieldsetCls">
            <legend class="w-auto">Contact Person Details</legend>
            <Row>
              <Col md={6}>
                <Row>
                  <Col md={4}>
                    <label>Name</label>
                  </Col>
                  <Col md={7}>
                    <Form.Control
                      disabled={readOnly}
                      type="text"
                      name="contact_person_name"
                      value={mandap.contact_person_name}
                      onChange={(e) =>
                        handleInputMandap("contact_person_name", e.target.value)
                      }
                      isInvalid={!!errors.contact_person_name}
                    />
                    {/* <Form.Control.Feedback type='invalid'>
                        { errors.contact_person_name }
                    </Form.Control.Feedback> */}
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label>Mobile</label>
                  </Col>
                  <Col md={7}>
                    <Form.Control
                      disabled={readOnly}
                      type="number"
                      name="mobile_no"
                      value={mandap.mobile_no}
                      onChange={(e) =>
                        handleInputMandap("mobile_no", e.target.value)
                      }
                      isInvalid={!!errors.mobile_no}
                    />
                    {/* <Form.Control.Feedback type='invalid'>
                        { errors.mobile_no }
                    </Form.Control.Feedback> */}
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Row>
                  <Col md={4}>
                    <label>Alternate Number</label>
                  </Col>
                  <Col md={7}>
                    <Form.Control
                      disabled={readOnly}
                      type="number"
                      name="lane_line_number"
                      value={mandap.lane_line_number}
                      onChange={(e) =>
                        handleInputMandap("lane_line_number", e.target.value)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label>Email</label>
                  </Col>
                  <Col md={7}>
                    <Form.Control
                      disabled={readOnly}
                      type="email"
                      name="email"
                      value={mandap.email}
                      onChange={(e) =>
                        handleInputMandap("email", e.target.value)
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </fieldset>
          <fieldset class="border p-2 fieldsetCls">
            <legend class="w-auto">Mandap Features</legend>
            <Row>
              <Col md={6}>
                {/* <Row>
                        <Col md={4}>
                            <label>Vendor</label>
                        </Col>
                        <Col md={7}>
                        <Select className={`selectBox ${!! errors.vendor_id  ? 'inValidField' : ''}`}
                            value={vendor}
                            onChange={e=>handleSelect('vendor_id',setVendor,e)}
                            options={vendorList}
                            isClearable isDisabled={props.propsData && props.propsData.editMode}
                        />
                        {errors.vendor_id ? 
                            <Form.Control.Feedback type='invalid' className='d-block'>
                                { errors.vendor_id }
                            </Form.Control.Feedback> : ''}
                        </Col>
                    </Row> */}
                <Row>
                  <Col md={4}>
                    <Form.Label>Mandap Name</Form.Label>
                  </Col>
                  <Col md={7}>
                    <Form.Control
                      disabled={readOnly}
                      type="text"
                      name="mandap_name"
                      value={mandap.mandap_name}
                      onChange={(e) =>
                        handleInputMandap("mandap_name", e.target.value)
                      }
                      isInvalid={!!errors.mandap_name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.mandap_name}
                    </Form.Control.Feedback>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label>Mandap Type</label>
                  </Col>
                  <Col md={7}>
                    <Select
                      className={`selectBox ${
                        !!errors.mandap_type_id ? "inValidField" : ""
                      }`}
                      isDisabled={readOnly}
                      value={type}
                      onChange={(e) =>
                        handleSelect("mandap_type_id", settype, e)
                      }
                      options={typeList}
                      isClearable
                    />
                    {errors.mandap_type_id ? (
                      <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.mandap_type_id}
                      </Form.Control.Feedback>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label>Mandap Classification</label>
                  </Col>
                  <Col md={7}>
                    <Select
                      className={`selectBox ${
                        !!errors.mandap_classification_id ? "inValidField" : ""
                      }`}
                      isDisabled
                      value={classification}
                      onChange={(e) =>
                        handleSelect(
                          "mandap_classification_id",
                          setclassification,
                          e
                        )
                      }
                      options={classificationList}
                      isClearable
                    />
                    {errors.mandap_classification_id ? (
                      <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.mandap_classification_id}
                      </Form.Control.Feedback>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
                {/* <Row>
                    <Col md={4}>
                        <label>Address 2</label>
                    </Col>
                    <Col md={7}>
                    <Form.Control disabled={readOnly} as='textarea' rows="4" cols="50" name="address_2" value={mandap.address_2} onChange={e=> handleInputMandap('address_2',e.target.value)} />
                    </Col>
                </Row> */}
              </Col>
              <Col md={6}>
                <Row>
                  <Col md={4}>
                    <label>Floating Capacity</label>
                  </Col>
                  <Col md={7}>
                    <input
                      disabled={readOnly}
                      type="number"
                      name="guest"
                      value={mandap.guest}
                      onChange={(e) =>
                        handleInputMandap("guest", e.target.value)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <Form.Label>Area (sqft)</Form.Label>
                  </Col>
                  <Col md={7}>
                    <Form.Control
                      disabled={readOnly}
                      type="number"
                      name="area_sqft"
                      value={mandap.area_sqft}
                      onChange={(e) =>
                        handleInputMandap("area_sqft", e.target.value)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label>Address</label>
                  </Col>
                  <Col md={7}>
                    <Form.Control
                      disabled={readOnly}
                      as="textarea"
                      rows="4"
                      cols="50"
                      name="address_1"
                      value={mandap.address_1}
                      onChange={(e) =>
                        handleInputMandap("address_1", e.target.value)
                      }
                      isInvalid={!!errors.address_1}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.address_1}
                    </Form.Control.Feedback>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <Form.Label>Pincode</Form.Label>
                  </Col>
                  <Col md={7}>
                    <Form.Control
                      disabled={readOnly}
                      type="number"
                      name="pincode"
                      value={mandap.pincode}
                      onChange={(e) =>
                        handleInputMandap("pincode", e.target.value)
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </fieldset>
          <fieldset class="border p-2 fieldsetCls">
            <legend class="w-auto">Map</legend>
            <Row>
              <Col md={6}>
                <Row>
                  <Col md={4}>
                    <label>
                      Location{" "}
                      <HiRefresh
                        size="24"
                        className="locIcon"
                        title="Fetch location from address"
                        onClick={() => getLocation()}
                      />
                    </label>
                  </Col>
                  <Col md={7}>
                    <Location
                      disabled={readOnly}
                      getCoordinates={getCoordinates}
                      hasAddr={hasAddr}
                      locData={locData}
                      data={hasAddr ? locData : props.mandap || null}
                    />
                  </Col>
                </Row>
                <Row className="pad-t-20">
                  <Col md={4}>
                    <label>Landmark</label>
                  </Col>
                  <Col md={7}>
                    <input
                      disabled={readOnly}
                      type="text"
                      name="land_mark"
                      value={mandap.land_mark}
                      onChange={(e) =>
                        handleInputMandap("land_mark", e.target.value)
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Row>
                  <Col md={4}>
                    <label>Area</label>
                  </Col>
                  <Col md={7}>
                    <Select
                      className="selectBox"
                      isDisabled={readOnly}
                      value={area}
                      onChange={(e) => handleSelect("area_id", setarea, e)}
                      options={areaList}
                      isClearable
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label>City</label>
                  </Col>
                  <Col md={7}>
                    <Select
                      className="selectBox"
                      isDisabled
                      value={city}
                      onChange={(e) => handleSelect("city_id", setcity, e)}
                      options={cityList}
                      isClearable
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label>State</label>
                  </Col>
                  <Col md={7}>
                    <Select
                      className="selectBox"
                      isDisabled
                      value={state}
                      onChange={(e) => handleSelect("state_id", setstate, e)}
                      options={stateList}
                      isClearable
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label>On Main Road</label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="on_main_road"
                      value={mandap.on_main_road}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label>Residential Area</label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="residential_area"
                      value={mandap.residential_area}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label>Nearest Railway Station</label>
                  </Col>
                  <Col md={7}>
                    <input
                      disabled={readOnly}
                      type="text"
                      name="nearest_Railway_Station"
                      value={mandap.nearest_Railway_Station}
                      onChange={(e) =>
                        handleInputMandap(
                          "nearest_Railway_Station",
                          e.target.value
                        )
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label>Distance from Railway Station</label>
                  </Col>
                  <Col md={7}>
                    <input
                      disabled={readOnly}
                      type="text"
                      name="distance_from_railway_station"
                      value={mandap.distance_from_railway_station}
                      onChange={(e) =>
                        handleInputMandap(
                          "distance_from_railway_station",
                          e.target.value
                        )
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label>Nearest Bus Stand</label>
                  </Col>
                  <Col md={7}>
                    <input
                      disabled={readOnly}
                      type="text"
                      name="nearest_bus_stand"
                      value={mandap.nearest_bus_stand}
                      onChange={(e) =>
                        handleInputMandap("nearest_bus_stand", e.target.value)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label>Distance from Bus Stand</label>
                  </Col>
                  <Col md={7}>
                    <input
                      disabled={readOnly}
                      type="text"
                      name="distance_from_bus_stand"
                      value={mandap.distance_from_bus_stand}
                      onChange={(e) =>
                        handleInputMandap(
                          "distance_from_bus_stand",
                          e.target.value
                        )
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </fieldset>
          <fieldset class="border p-2 fieldsetCls">
            <legend class="w-auto">Hall</legend>
            <Row>
              <Col md={6}>
                <Row>
                  <Col md={4}>
                    <label>Hall (sqft)</label>
                  </Col>
                  <Col md={7}>
                    <Form.Control
                      disabled={readOnly}
                      type="number"
                      name="hall_sq_ft"
                      value={mandap.hall_sq_ft}
                      onChange={(e) =>
                        handleInputMandap("hall_sq_ft", e.target.value)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label>Hall Height (ft) </label>
                  </Col>
                  <Col md={7}>
                    <input
                      disabled={readOnly}
                      type="text"
                      name="hall_height"
                      value={mandap.hall_height}
                      onChange={(e) =>
                        handleInputMandap("hall_height", e.target.value)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label>Hall Level</label>
                  </Col>
                  <Col md={7}>
                    <Select
                      className="selectBox"
                      isDisabled={readOnly}
                      value={hallLevel}
                      onChange={(e) =>
                        handleSelect("hall_level", setHallLevel, e)
                      }
                      options={levels}
                      isClearable
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Row>
                  <Col md={4}>
                    <label>Pillarless Hall</label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="pillarless_hall"
                      value={mandap.pillarless_hall}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <Form.Label>Seating Capacity</Form.Label>
                  </Col>
                  <Col md={7}>
                    <Form.Control
                      disabled={readOnly}
                      type="number"
                      name="seating_capacity"
                      value={mandap.seating_capacity}
                      onChange={(e) =>
                        handleInputMandap("seating_capacity", e.target.value)
                      }
                      isInvalid={!!errors.seating_capacity}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.seating_capacity}
                    </Form.Control.Feedback>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label>Lift Access</label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={
                        (hallLevel && hallLevel.value === "ground_level") ||
                        readOnly
                      }
                      handleChange={handleMandapCheckBox}
                      name="lift_access_to_hall"
                      value={mandap.lift_access_to_hall}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label>AC Hall</label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="hall_ac"
                      value={mandap.hall_ac}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </fieldset>
          <fieldset class="border p-2 fieldsetCls">
            <legend class="w-auto">Stage</legend>
            <Row>
              <Col md={6}>
                <Row>
                  <Col md={4}>
                    <label>Stage Available</label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="stage_available"
                      value={mandap.stage_available}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label>Primary Stage Dimension (ft)</label>
                  </Col>
                  <Col md={7}>
                    <input
                      disabled={readOnly}
                      type="text"
                      name="primary_stage_dim_ft"
                      value={mandap.primary_stage_dim_ft}
                      onChange={(e) =>
                        handleInputMandap(
                          "primary_stage_dim_ft",
                          e.target.value
                        )
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label>Homam/Haven Fire Rituals</label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="homam_havan_fireritual"
                      value={mandap.homam_havan_fireritual}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Row>
                  <Col md={4}>
                    <label>Secondary Stage Available</label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="secondary_stage_available"
                      value={mandap.secondary_stage_available}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label>Secondary Stage Dimension (ft)</label>
                  </Col>
                  <Col md={7}>
                    <input
                      disabled={readOnly}
                      type="text"
                      name="secondary_stage_dim_ft"
                      value={mandap.secondary_stage_dim_ft}
                      onChange={(e) =>
                        handleInputMandap(
                          "secondary_stage_dim_ft",
                          e.target.value
                        )
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label>Handicap Ramp</label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="handicap_ramp"
                      value={mandap.handicap_ramp}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </fieldset>

          <fieldset class="border p-2 fieldsetCls">
            <legend class="w-auto">Dining</legend>
            <Row>
              <Col md={6}>
                <Row>
                  <Col md={4}>
                    <Form.Label>Dining Capacity</Form.Label>
                  </Col>
                  <Col md={7}>
                    <Form.Control
                      disabled={readOnly}
                      type="number"
                      name="dining_capacity"
                      value={mandap.dining_capacity}
                      onChange={(e) =>
                        handleInputMandap("dining_capacity", e.target.value)
                      }
                      isInvalid={!!errors.dining_capacity}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.dining_capacity}
                    </Form.Control.Feedback>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label>Dining Level</label>
                  </Col>
                  <Col md={7}>
                    <Select
                      className="selectBox"
                      isDisabled={readOnly}
                      value={diningLevel}
                      onChange={(e) =>
                        handleSelect("dining_level", setDiningLevel, e)
                      }
                      options={levels}
                      isClearable
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label>Lift Access to Dining Hall</label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={
                        (diningLevel && diningLevel.value === "ground_level") ||
                        readOnly
                      }
                      handleChange={handleMandapCheckBox}
                      name="lift_access_to_dining_hall"
                      value={mandap.lift_access_to_dining_hall}
                    />{" "}
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label>AC Dining</label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="dining_ac"
                      value={mandap.dining_ac}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label>Catering</label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="catering"
                      value={mandap.catering}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label>Outdoor Catering Allowed</label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="outdoor_catering_allowed"
                      value={mandap.outdoor_catering_allowed}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label>Non-veg Cooking allowed</label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="non_veg_cooking_allowed"
                      value={mandap.non_veg_cooking_allowed}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label>Non-veg Service Allowed</label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="non_veg_service_allowed"
                      value={mandap.non_veg_service_allowed}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Row>
                  <Col md={4}>
                    <label>Outdoor Buffet Available</label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="outdoor_buffet"
                      value={mandap.outdoor_buffet}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label>Cooking Fuel Provided</label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="cooking_fuel_provided"
                      value={mandap.cooking_fuel_provided}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label>Cooking Fuel Used</label>
                  </Col>
                  <Col md={7}>
                    <Select
                      className="selectBox"
                      isDisabled={readOnly}
                      value={cookingFuel}
                      onChange={(e) =>
                        handleSelect("cooking_fuel_used", setcookingFuel, e)
                      }
                      options={fuels}
                      isClearable
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label>Piped Gas</label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="piped_gas"
                      value={mandap.piped_gas}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label>Food Type</label>
                  </Col>
                  <Col md={7}>
                    <Select
                      className={`selectBox ${
                        !!errors.food_type ? "inValidField" : ""
                      }`}
                      value={food}
                      isDisabled={readOnly}
                      onChange={(e) => handleSelect("food_type", setFood, e)}
                      options={foodTypes}
                      isClearable
                    />
                    {errors.food_type ? (
                      <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.food_type}
                      </Form.Control.Feedback>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label>Cooking Utensils Available</label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="cooking_utensils_available"
                      value={mandap.cooking_utensils_available}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label>Buffet Crockery Available</label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="buffet_crockery_available"
                      value={mandap.buffet_crockery_available}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </fieldset>
          <fieldset class="border p-2 fieldsetCls">
            <legend class="w-auto">Parking</legend>
            <Row>
              <Col md={6}>
                <Row>
                  <Col md={4}>
                    <label>Car Parking Capacity</label>
                  </Col>
                  <Col md={7}>
                    <Form.Control
                      disabled={readOnly}
                      type="number"
                      name="car_parking_capacity"
                      value={mandap.car_parking_capacity}
                      onChange={(e) =>
                        handleInputMandap(
                          "car_parking_capacity",
                          e.target.value
                        )
                      }
                      isInvalid={!!errors.car_parking_capacity}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.car_parking_capacity}
                    </Form.Control.Feedback>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label>Bike Parking Capacity</label>
                  </Col>
                  <Col md={7}>
                    <Form.Control
                      disabled={readOnly}
                      type="number"
                      name="bike_parking_capacity"
                      value={mandap.bike_parking_capacity}
                      onChange={(e) =>
                        handleInputMandap(
                          "bike_parking_capacity",
                          e.target.value
                        )
                      }
                      isInvalid={!!errors.bike_parking_capacity}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.bike_parking_capacity}
                    </Form.Control.Feedback>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Row>
                  <Col md={4}>
                    <label>Valet Parking</label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="valet_parking"
                      value={mandap.valet_parking}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </fieldset>
          <fieldset class="border p-2 fieldsetCls">
            <legend class="w-auto">Rooms</legend>
            <Row>
              <Col md={6}>
                {/* <Row>
                    <Col md={4}>
                        <label>No. of Rooms</label>
                    </Col>
                    <Col md={7}>
                    <Form.Control disabled={readOnly} type='number' name="no_of_rooms" value={mandap.no_of_rooms} onChange={e=> handleInputMandap('no_of_rooms',e.target.value)} isInvalid={!! errors.no_of_rooms} />
                    <Form.Control.Feedback type='invalid'>
                        { errors.no_of_rooms }
                    </Form.Control.Feedback>
                    </Col>
                </Row> */}
                <Row>
                  <Col md={4}>
                    <label>No. of AC Rooms</label>
                  </Col>
                  <Col md={7}>
                    <input
                      disabled={readOnly}
                      type="number"
                      name="no_of_ac_rooms"
                      value={mandap.no_of_ac_rooms}
                      onChange={(e) =>
                        handleInputMandap("no_of_ac_rooms", e.target.value)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label>No. of Non-AC Rooms</label>
                  </Col>
                  <Col md={7}>
                    <input
                      disabled={readOnly}
                      type="number"
                      name="no_of_nonac_rooms"
                      value={mandap.no_of_nonac_rooms}
                      onChange={(e) =>
                        handleInputMandap("no_of_nonac_rooms", e.target.value)
                      }
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                {/* <Row>
                    <Col md={4}>
                        <label>Groom Rooms</label>
                    </Col>
                    <Col md={7}>
                    <CustomSwitch disabled={readOnly} handleChange={handleMandapCheckBox} name = 'grooms_room' value={mandap.grooms_room} />
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <label>Bride Rooms</label>
                    </Col>
                    <Col md={7}>
                    <CustomSwitch disabled={readOnly} handleChange={handleMandapCheckBox} name = 'bride_room' value={mandap.bride_room} />
                    </Col>
                </Row> */}
                <Row>
                  <Col md={4}>
                    <label>AC Type</label>
                  </Col>
                  <Col md={7}>
                    <Select
                      className={`selectBox ${
                        !!errors.ac_type ? "inValidField" : ""
                      }`}
                      value={ac}
                      isDisabled={readOnly}
                      onChange={(e) => handleSelect("ac_type", setAC, e)}
                      options={ACTypes}
                      isClearable
                    />
                    {errors.ac_type ? (
                      <Form.Control.Feedback type="invalid" className="d-block">
                        {errors.ac_type}
                      </Form.Control.Feedback>
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label>Safe Available</label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="safe_available"
                      value={mandap.safe_available}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label>Rooms - Terms & Conditions</label>
                  </Col>
                  <Col md={7}>
                    <Form.Control
                      disabled={readOnly}
                      as="textarea"
                      rows="4"
                      cols="50"
                      name="rooms_terms_and_condition"
                      value={mandap.rooms_terms_and_condition}
                      onChange={(e) =>
                        handleInputMandap(
                          "rooms_terms_and_condition",
                          e.target.value
                        )
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </fieldset>
          <fieldset class="border p-2 fieldsetCls">
            <legend class="w-auto">Others</legend>
            <Row>
              <Col md={6}>
                <Row>
                  <Col md={4}>
                    <label>WIFI</label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="wifi"
                      value={mandap.wifi}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label>Common Area AC</label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="common_area_ac"
                      value={mandap.common_area_ac}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label>Backup Generator</label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="generator_backup"
                      value={mandap.generator_backup}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label>Serving Available</label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="serving_available"
                      value={mandap.serving_available}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Row>
                  <Col md={4}>
                    <label>Outside Entertainment Available</label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="outside_entertainment_allowed"
                      value={mandap.outside_entertainment_allowed}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label>Outside Vendors Allowed</label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="outside_vendor_allowed"
                      value={mandap.outside_vendor_allowed}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label>Other Facilities</label>
                  </Col>
                  <Col md={7}>
                    <input
                      disabled={readOnly}
                      type="text"
                      name="other_facilities"
                      value={mandap.other_facilities}
                      onChange={(e) =>
                        handleInputMandap("other_facilities", e.target.value)
                      }
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <label>Other Terms & Conditions</label>
                  </Col>
                  <Col md={7}>
                    <Form.Control
                      disabled={readOnly}
                      as="textarea"
                      rows="4"
                      cols="50"
                      name="mandap_terms_and_condition"
                      value={mandap.mandap_terms_and_condition}
                      onChange={(e) =>
                        handleInputMandap(
                          "mandap_terms_and_condition",
                          e.target.value
                        )
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Row>
                  <Col md={4}>
                    <label>Having Offers</label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="having_offer"
                      value={mandap.having_offer}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                {mandap.having_offer ? (
                  <Row>
                    <Col md={4}>
                      <label>Offers</label>
                    </Col>
                    <Col md={7}>
                      <Form.Control
                        disabled={readOnly}
                        as="textarea"
                        rows="4"
                        cols="50"
                        name="offers"
                        value={mandap.offers}
                        onChange={(e) =>
                          handleInputMandap("offers", e.target.value)
                        }
                      />
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Row>
                  <Col md={4}>
                    <label>Mark as Popular</label>
                  </Col>
                  <Col md={7}>
                    <CustomSwitch
                      disabled={readOnly}
                      handleChange={handleMandapCheckBox}
                      name="is_popular"
                      value={mandap.is_popular}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Row>
                  <Col md={2}>
                    <label>Title Description</label>
                  </Col>
                  <Col md={10}>
                    <Form.Control
                      disabled={readOnly}
                      as="textarea"
                      rows="4"
                      cols="50"
                      name="title_description"
                      placeholder="Description to be displayed in title"
                      value={mandap.title_description}
                      onChange={(e) =>
                        handleInputMandap("title_description", e.target.value)
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Row>
                  <Col md={2}>
                    <label>Description</label>
                  </Col>
                  <Col md={10}>
                    <Form.Control
                      disabled={readOnly}
                      as="textarea"
                      rows="4"
                      cols="50"
                      name="mandap_description"
                      placeholder="Describe about the mandapam in few words..."
                      value={mandap.mandap_description}
                      onChange={(e) =>
                        handleInputMandap("mandap_description", e.target.value)
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </fieldset>
        </Loader>
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </Col>
    </Row>
  );
};

export default Mandapam;
