import React,{ useState,useEffect} from "react";
import { Container,Col,Row,Card,CardBody } from "reactstrap";
import { Button,Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axios,{post,get,patch} from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import config from '../../../config';
import Loader from '../../Helper/Loader'
import CustomSwitch from "../../Helper/CustomSwitch";
import empty from 'is-empty'
import Select from 'react-select'
import Breadcrumbs from "../../Helper/Breadcrumbs";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import moment from 'moment';
import {BiRupee} from 'react-icons/bi'
import OtherPricing from './OtherPricing'

const PricingForm = (props) =>{
    let history = useHistory();
  let tokenKey = {Authorization:'Token '+sessionStorage.getItem('tokenKey')};
  let propsState = props.history.location.state;
  const [form, setForm] = useState({});
  const [indexVal, setIndexVal] = useState(0);
  const [editMode, setEditMode] = useState(propsState && propsState.editMode || false);
  const [errors, setErrors] = useState({});
  const [loading, setloading] = useState(true);
  const [overlay, setoverlay] = useState(false);
  const [pricingType, setPricingType] = useState(null);
  const [FulldayStartTime, setFulldayStartTime] = useState(null);
  const [FulldayEndTime, setFulldayEndTime] = useState(null);
  const [HalfdayMorningStartTime, setHalfdayMorningStartTime] = useState(null);
  const [HalfdayMorningEndTime, setHalfdayMorningEndTime] = useState(null);
  const [HalfdayEveningStartTime, setHalfdayEveningStartTime] = useState(null);
  const [HalfdayEveningEndTime, setHalfdayEveningEndTime] = useState(null);
  const [includeList, setincludeList] = useState([]);
  const [excludeList, setexcludeList] = useState([]);
  const [priceDetails, setPriceDetails] = useState([])
  const [typeData, setTypeData] = useState([]);

  let priceTypes = [{label:'Price per plate', value : 'per_plate'},{label:'Price per day', value : 'per_day'}]
  
  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    };
    let BreadcrumbData =[
        {
            title:'Pricing',
            href:'/pricing',
            state:{
                data : propsState && propsState.data
            }
        },
        {
            title:editMode ? 'View' :'Create',
            href:'#'
        }
    ]
    const [tab, setTab] = useState({
        details:true,
        others:false,
        special:false
    });
      const [tabData, setTabData] = useState([
          {
              name:'Mandap Pricing Details',
              id:'details',
              show:true
          },
          {
            name:'Pricing for Non-muhurtham dates ',
            id:'others',
            show:true
          },
          {
            name:'Special Prices',
            id:'special',
            show:false
           }
      ]);
      const handleInput = (keyName,value) => {
        setForm({
            ...form,
            [keyName] : value
        })
        if (keyName && !!errors[keyName]) setErrors({
            ...errors,
            [keyName]: null
          })
    }
    const handleSelect = (keyName,setFn,e,isMulti) =>{
        setFn(e);
        setForm({
            ...form,
            [keyName] : isMulti ? e : (e && e.value) || null
        })
        if (keyName && !!errors[keyName]) setErrors({
            ...errors,
            [keyName]: null
          })
    }

    const onChangeTime = (value, keyName, setFn) => {
        let val = moment(value).format('HH:mm:ss')
        setFn(value)
        setForm({
            ...form,
            [keyName] : val
        })
    }
    
    const findErrors = () => {
        const { name } = form
        const newErrors = {}
        // if ( empty(pricingType) ) newErrors.pricingType = 'This is a required field';
    
        return newErrors;
       }

    const saveForm = () =>{
        let id =editMode ? propsState.id : null, method = editMode ? 'put' : 'post';
    const newErrors = findErrors();
    if ( Object.keys(newErrors).length > 0 ) {
      setErrors(newErrors)
      toast.error('Enter all required details to proceed', toastAttr);
    } else {
      setLoader(true);
        let url = editMode ? config.service+'/masters/mandap_pricing/update/'+id+'/' : config.service+'/masters/mandap_pricing/create/', data=JSON.parse(JSON.stringify(form));
        let include=[], exclude=[]
        data.mandap_id = propsState.data.id;
        includeList.map(obj=>{include.push(obj.value);})
        excludeList.map(obj=>{exclude.push(obj.value);})
        data.include_list = include;
        data.exclude_list = exclude;
        axios({
            url: url,
            method: method,
            headers: tokenKey,
            data: data,
          }).then(res=>{
              if(editMode) setForm(res.data)
              toast.success('Saved successfully', toastAttr);
              setLoader(false);
              setTimeout(() => {
                renderListView();
              }, 1000);
        }).catch(err=>{
          if(err.response.status === 401) window.location.href = '/'
          else
            toast.error('Saving Failed', toastAttr);
            setLoader(false);
        })
    }
    }

    const saveFunctionTypePrice = ()=> {
        if(!empty(typeData)){
            setLoader(true);
            let id =propsState && propsState.data.id || null, method = editMode ? 'post' : 'post', postData = [];
            let url = editMode ? config.service+'/masters/mandap-functiontype-pricing/'+id+'/' : config.service+'/masters/mandap-functiontype-pricing/'+id+'/', data=typeData;
            data.map(type=>{
                type.typeObj.map(obj=>{
                    postData.push({
                        function_type_id : obj.value,
                        price : type.price
                    })
                })
            })
            axios({
                url: url,
                method: method,
                headers: tokenKey,
                data: {prices:postData},
            }).then(res=>{
                if(editMode) setForm(res.data)
                toast.success('Saved successfully', toastAttr);
                setLoader(false);
                setTimeout(() => {
                    renderListView();
                }, 1000);
            }).catch(err=>{
            if(err.response.status === 401) window.location.href = '/'
            else
                toast.error('Saving Failed', toastAttr);
                setLoader(false);
            })
        }
    }

    const updateData = data => {
        setTypeData(data);
    }

    const renderListView = () =>{
        history.push({
            pathname : '/pricing',
            state:{
                data : propsState && propsState.data
            }
        })
    }
    const setLoader = (val) => {
        setloading(val);setoverlay(val);
      }
    
    
      useEffect(() => {
        get(config.service+'/masters/pricelist/',{headers:tokenKey}).then(resp=>{
            let details = [];
            resp.data.map(obj=>{
                details.push({label:obj.name,value:obj.id})
            })
            setPriceDetails(details);
            if(propsState && propsState.editMode)
            get(config.service+'/masters/mandap_pricing/'+propsState.id+'/',{headers:tokenKey}).then(res=>{
                // let type = priceTypes.filter(temp=>temp.value === res.data.pricing_type);
                let include = [],exclude = [];
                res.data.include_list && res.data.include_list.map(listId=>{
                    let label = resp.data.filter(row=> row.id === listId)[0]
                    include.push({value:listId,label: label.name})
                  })
                res.data.exclude_list && res.data.exclude_list.map(listId=>{
                    let label = resp.data.filter(row=> row.id === listId)[0]
                    exclude.push({value:listId,label: label.name})
                  })
                // setPricingType(type[0]); 
                setincludeList(include); setexcludeList(exclude);
                const d = moment(new Date()).format('L');
                const fullStart = moment(d +' '+ res.data.fd_start_time).format();
                const fullEnd = moment(d +' '+ res.data.fd_end_time).format();
                const mornStart = moment(d +' '+ res.data.hf_am_start_time).format();
                const mornEnd = moment(d +' '+ res.data.hf_am_end_time).format();
                const eveStart = moment(d +' '+ res.data.hf_pm_start_time).format();
                const eveEnd = moment(d +' '+ res.data.hf_pm_end_time).format();
                setFulldayStartTime(moment(fullStart).toDate())
                setFulldayEndTime(moment(fullEnd).toDate())
                setHalfdayMorningStartTime(moment(mornStart).toDate())
                setHalfdayMorningEndTime(moment(mornEnd).toDate())
                setHalfdayEveningStartTime(moment(eveStart).toDate())
                setHalfdayEveningEndTime(moment(eveEnd).toDate())
                setForm(res.data);
            })
            setLoader(false);
        }).catch(err=>{
            setLoader(false);
          })
      }, [])

      const handleTab = (e,index)=>{
        let tabValue = e.target.id;
        setIndexVal(index);
        setTab({
            details:false,
            others : false,
            special:false,
            [tabValue] : true
        })
    };

    return(
        <Container className="vendor">
        <Row>
      <Col md={12} className='contBody'>
        <Card>
          <CardBody className='theme-light'>
            <div className="card__title pageNav">
            <div className="bold-text">
              <Breadcrumbs data={BreadcrumbData} />
              </div>
                      </div>
            <Row className='formBtnsDiv'>
                <Col md={12}>
                        <Button variant='primary' onClick={tab.details ? saveForm : saveFunctionTypePrice}>Save</Button> 
                        <Button variant='secondary' onClick={e=>{renderListView()}}>Cancel</Button>
                </Col>
            </Row>
            <Row className='formBtnsDiv'>
                <Col md={12} style={{margin:'5px 0'}}>
        {/* {editMode ? <StatusSteps data={{status: form.status}}  />  : ''} */}
                </Col>
            </Row>
            <Row className='formDiv'>
                <Col md={12}>
                    <nav>
                        <div className='nav nav-tabs' id='nav-tab' role='tablist'>
                            <div className='tabList'>
                                {tabData.map((tab,index)=>{
                                    let disCls = tab.disable;
                                    return tab.show ? (
                                        <a className={`nav-item nav-link ${indexVal === index ? 'active' : ''}`} onClick={e=> handleTab(e,index)}
                                        id={tab.id} name={index} data-toggle='tab' role='tab' aria-controls='nav-details' aria-selected='true'>
                                            {tab.name}
                                        </a>
                                    ) : '';
                                })}
                            </div>
                        </div>
                    </nav>
                    <div className='tabContent'>
                        <Form className="form defaultForm" >
                        <Loader loading={loading} overlay={overlay}>
                            {tab.details ? 
                                <>
                                <Row>
                                    <Col md={7}>
                                    <Row>
                                        <Col md={4}>
                                            <Form.Label>Blocking price (<BiRupee size={15} />) </Form.Label>
                                        </Col>
                                        <Col md={7}>
                                            <Form.Control type='number' name="reservation_price" value={form.reservation_price} onChange={e=> handleInput('reservation_price',e.target.value)} isInvalid={!! errors.reservation_price} />
                                            <Form.Control.Feedback type='invalid'>
                                                { errors.reservation_price }
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={4}>
                                            <Form.Label>Hide Price for Customers (<BiRupee size={15} />) </Form.Label>
                                        </Col>
                                        <Col md={7}>
                                            <CustomSwitch handleChange={handleInput} name = 'hide_price' value={form.hide_price} />
                                        </Col>
                                    </Row>
                                    </Col>
                                    </Row>
                                    <fieldset className="border p-2 fieldsetCls">
                                        <legend className="w-auto" style={{fontWeight:'500' }}>Price per plate details</legend>
                                        <Row>
                                            <Col md={6}>
                                                <Row>
                                                    <Col md={4}>
                                                        <Form.Label>Breakfast - Veg (<BiRupee size={15} />)</Form.Label>
                                                    </Col>
                                                    <Col md={7}>
                                                        <Form.Control type='number' name="pr_breakfast_vg" value={form.pr_breakfast_vg} onChange={e=> handleInput('pr_breakfast_vg',e.target.value)} isInvalid={!! errors.pr_breakfast_vg} />
                                                        <Form.Control.Feedback type='invalid'>
                                                            { errors.pr_breakfast_vg }
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={4}>
                                                        <Form.Label>Lunch - Veg (<BiRupee size={15} />)</Form.Label>
                                                    </Col>
                                                    <Col md={7}>
                                                        <Form.Control type='number' name="pr_lunch_nvg" value={form.pr_lunch_nvg} onChange={e=> handleInput('pr_lunch_nvg',e.target.value)} isInvalid={!! errors.pr_lunch_nvg} />
                                                        <Form.Control.Feedback type='invalid'>
                                                            { errors.pr_lunch_nvg }
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={4}>
                                                        <Form.Label>Dinner - Veg (<BiRupee size={15} />)</Form.Label>
                                                    </Col>
                                                    <Col md={7}>
                                                        <Form.Control type='number' name="pr_dinner_vg" value={form.pr_dinner_vg} onChange={e=> handleInput('pr_dinner_vg',e.target.value)} isInvalid={!! errors.pr_dinner_vg} />
                                                        <Form.Control.Feedback type='invalid'>
                                                            { errors.pr_dinner_vg }
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={6}>
                                            <Row>
                                                    <Col md={4}>
                                                        <Form.Label>Breakfast - Non Veg (<BiRupee size={15} />)</Form.Label>
                                                    </Col>
                                                    <Col md={7}>
                                                        <Form.Control type='number' name="pr_breakfast_nvg" value={form.pr_breakfast_nvg} onChange={e=> handleInput('pr_breakfast_nvg',e.target.value)} isInvalid={!! errors.pr_breakfast_nvg} />
                                                        <Form.Control.Feedback type='invalid'>
                                                            { errors.pr_breakfast_nvg }
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={4}>
                                                        <Form.Label>Lunch - Non Veg (<BiRupee size={15} />)</Form.Label>
                                                    </Col>
                                                    <Col md={7}>
                                                        <Form.Control type='number' name="pr_lunch_vg" value={form.pr_lunch_vg} onChange={e=> handleInput('pr_lunch_vg',e.target.value)} isInvalid={!! errors.pr_lunch_vg} />
                                                        <Form.Control.Feedback type='invalid'>
                                                            { errors.pr_lunch_vg }
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={4}>
                                                        <Form.Label>Dinner - Non Veg (<BiRupee size={15} />)</Form.Label>
                                                    </Col>
                                                    <Col md={7}>
                                                        <Form.Control type='number' name="pr_dinner_nvg" value={form.pr_dinner_nvg} onChange={e=> handleInput('pr_dinner_nvg',e.target.value)} isInvalid={!! errors.pr_dinner_nvg} />
                                                        <Form.Control.Feedback type='invalid'>
                                                            { errors.pr_dinner_nvg }
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            </Row>
                                    </fieldset> 
                                     
                                    <>
                                    <fieldset className="border p-2 fieldsetCls">
                                        <legend className="w-auto" style={{fontWeight:'500' }}>Full day details</legend>
                                        <Row>
                                            <Col md={6}>
                                                        <Row>
                                                            <Col md={4}>
                                                                <Form.Label>Pricing for Full day (<BiRupee size={15} />)</Form.Label>
                                                            </Col>
                                                            <Col md={7}>
                                                                <Form.Control type='number' name="fd_price" value={form.fd_price} onChange={e=> handleInput('fd_price',e.target.value)} isInvalid={!! errors.fd_price} />
                                                                <Form.Control.Feedback type='invalid'>
                                                                    { errors.fd_price }
                                                                </Form.Control.Feedback>
                                                            </Col>
                                                        </Row> 
                                                        <Row>
                                                    <Col md={4}>
                                                        <label> Start Time</label>
                                                    </Col>
                                                    <Col md={7} className="timePicker">
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <TimePicker 
                                                                value={FulldayStartTime}
                                                                onChange={(newValue) => {
                                                                onChangeTime(newValue,'fd_start_time',setFulldayStartTime)
                                                        }}
                                                        renderInput={(params) => <TextField {...params} />}
                                                        />
                                                        </LocalizationProvider>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={4}>
                                                        <label>End Time</label>
                                                    </Col>
                                                    <Col md={7} className="timePicker">
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <TimePicker  
                                                                value={FulldayEndTime}
                                                                onChange={(newValue) => {
                                                                onChangeTime(newValue,'fd_end_time',setFulldayEndTime)
                                                        }}
                                                        renderInput={(params) => <TextField {...params} />}
                                                        />
                                                        </LocalizationProvider>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            </Row>
                                    </fieldset>
                                    <fieldset className="border p-2 fieldsetCls">
                                        <legend className="w-auto" style={{fontWeight:'500' }}> Half a day details</legend>
                                        <Row>
                                            <Col md={6}>
                                            <Row>
                                                    <Col md={4}>
                                                        <Form.Label>Pricing for Morning (<BiRupee size={15} />)</Form.Label>
                                                    </Col>
                                                    <Col md={7}>
                                                        <Form.Control type='number' name="hf_am_price" value={form.hf_am_price} onChange={e=> handleInput('hf_am_price',e.target.value)} isInvalid={!! errors.hf_am_price} />
                                                        <Form.Control.Feedback type='invalid'>
                                                            { errors.hf_am_price }
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={4}>
                                                        <label>Start Time - Morning</label>
                                                    </Col>
                                                    <Col md={7} className="timePicker">
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <TimePicker 
                                                                value={HalfdayMorningStartTime}
                                                                onChange={(newValue) => {
                                                                onChangeTime(newValue,'hf_am_start_time',setHalfdayMorningStartTime)
                                                        }}
                                                        renderInput={(params) => <TextField {...params} />}
                                                        />
                                                        </LocalizationProvider>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={4}>
                                                        <label>End Time - Morning</label>
                                                    </Col>
                                                    <Col md={7} className="timePicker">
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <TimePicker 
                                                                value={HalfdayMorningEndTime}
                                                                onChange={(newValue) => {
                                                                onChangeTime(newValue,'hf_am_end_time',setHalfdayMorningEndTime)
                                                        }}
                                                        renderInput={(params) => <TextField {...params} />}
                                                        />
                                                        </LocalizationProvider>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col md={6}>
                                                <Row>
                                                    <Col md={4}>
                                                        <Form.Label>Pricing for Evening (<BiRupee size={15} />)</Form.Label>
                                                    </Col>
                                                    <Col md={7}>
                                                        <Form.Control type='number' name="hf_pm_price" value={form.hf_pm_price} onChange={e=> handleInput('hf_pm_price',e.target.value)} isInvalid={!! errors.hf_pm_price} />
                                                        <Form.Control.Feedback type='invalid'>
                                                            { errors.hf_pm_price }
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={4}>
                                                        <label>Start Time - Evening</label>
                                                    </Col>
                                                    <Col md={7} className="timePicker">
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <TimePicker 
                                                                value={HalfdayEveningStartTime}
                                                                onChange={(newValue) => {
                                                                onChangeTime(newValue,'hf_pm_start_time',setHalfdayEveningStartTime)
                                                        }}
                                                        renderInput={(params) => <TextField {...params} />}
                                                        />
                                                        </LocalizationProvider>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={4}>
                                                        <label>End Time - Evening</label>
                                                    </Col>
                                                    <Col md={7} className="timePicker">
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <TimePicker 
                                                                value={HalfdayEveningEndTime}
                                                                onChange={(newValue) => {
                                                                onChangeTime(newValue,'hf_pm_end_time',setHalfdayEveningEndTime)
                                                        }}
                                                        renderInput={(params) => <TextField {...params} />}
                                                        />
                                                        </LocalizationProvider>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </fieldset>
                                    </> 
                                     <fieldset className="border p-2 fieldsetCls">
                                        <legend className="w-auto" style={{fontWeight:'500' }}>Inclusions & Exclusions</legend>
                                        <Row>
                                            <Col md={2}>
                                                <label>Price Includes</label>
                                            </Col>
                                            <Col md={5}>
                                            <Select className='selectBox' isMulti
                                                value={includeList}
                                                onChange={e=>handleSelect('include_list',setincludeList,e, true)}
                                                options={priceDetails}
                                                isClearable
                                            />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={2}>
                                                <label>Price Excludes</label>
                                            </Col>
                                            <Col md={5}>
                                            <Select className='selectBox' isMulti
                                                value={excludeList}
                                                onChange={e=>handleSelect('exclude_list',setexcludeList,e,true)}
                                                options={priceDetails}
                                                isClearable
                                            />
                                            </Col>
                                        </Row>
                                    </fieldset></>
                          : ''}
                          {tab.others ? <OtherPricing updateData={updateData} mandapId={propsState.data.id} /> : ''}
                        </Loader>
                        </Form>
                    </div>
                </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
      </Row>
      <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            />
      </Container>
);
};


        
export default PricingForm;