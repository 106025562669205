import React,{useState,useEffect} from "react";
import Geocode from "react-geocode";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";

//AIzaSyAx9kGOkekI0SGzFPpi6vKk5yRRZWv8YV0
const MyMapComponent = compose(
  withProps({
    googleMapURL:
    "https://maps.googleapis.com/maps/api/js?key=AIzaSyBUXjxpjnRkBaXZmvqqMOERqpUpcLi-17c&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `300px` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)(props => {

  const script = document.createElement('script');
    script.src = "https://maps.googleapis.com/maps/api/js?sensor=false&callback=initMap";
    script.async = true;
    script.defer = true

    let data = props.data &&  props.data.latitude && props.data.longitude ? {lat : Number(props.data.latitude), lng: Number(props.data.longitude)} : { lat: 13.067439, lng:  80.237617 };
    const [coord, setcoord] = useState(data);
    const getMapCoords = ev =>{
        let data = {
            lat : ev.latLng.lat(),
            lng : ev.latLng.lng()
        };
        setcoord(data);
        props.getCoordinates(data);
    }
    useEffect(() => {
      let geocoder = new window.google.maps.Geocoder();
        if(props.hasAddr && props.data && props.data.address_1)
        geocoder.geocode( { 'address': props.data.address_1}, function(results, status) {
          if (status == 'OK') {
            var latitude = results[0].geometry.location.lat();
            var longitude = results[0].geometry.location.lng();
            console.log("📍 Coordinates: ", { latitude, longitude });
            setcoord({lat:latitude,lng:longitude})
          } else {
              console.log('Geocode was not successful for the following reason: ' + status);
          }
        });
      }, [props.hasAddr,props.locData]);
  
  
      const getCoord = pos => {
        setcoord({
  
              lat : pos.coords.latitude,
              lng : pos.coords.longitude
            });
          }

          const handleErr = err => {
            console.log(err)
          }
      
          useEffect(()=>{
            navigator.geolocation.getCurrentPosition(getCoord,handleErr);
      
    },[])
    return(
  <GoogleMap defaultZoom={16} center={coord} onClick={ev => {getMapCoords(ev) }}>
      <Marker position={coord} />
  </GoogleMap>);
});

export default MyMapComponent;
