import React,{useState,useEffect} from 'react';
import { Col, Container, Row, Card,CardBody, Modal,ModalHeader,ModalBody,ModalFooter } from 'reactstrap';
import { Button, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import axios,{post,get,patch} from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import config from '../../../config';
import Loader from '../../Helper/Loader'
import empty from 'is-empty'
import Topbar from '../../Layout/topbar/Topbar';
import PropTypes from 'prop-types';
import {TextField, Dialog, DialogActions,DialogContent, DialogContentText,DialogTitle} from '@mui/material'


const ChangePassword = (props) => {
  let history = useHistory();
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [openDialog, setopenDialog] = useState(true);
  const [showModal, setShowModal] = useState(false) 
  const [loading, setloading] = useState(false);
  const [overlay, setoverlay] = useState(false);

  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    };
  const [tab, setTab] = useState({
      details:true,
  });
    const [tabData, setTabData] = useState([
        {
            name:'Details',
            id:'details',
            show:true
        }
    ]);

    const handleInput = (keyName,value) => {
        setForm({
            ...form,
            [keyName] : value
        })
        if (keyName && !!errors[keyName]) setErrors({
            ...errors,
            [keyName]: null
          })
    }


  const redirectToLogin = () => {
      history.push("/")
  }

  const findErrors = () => {
    const { username,password, confirm_password } = form
    const newErrors = {}
    if ( empty(password) ) newErrors.password = 'This is a required field';
    if ( empty(confirm_password) ) newErrors.confirm_password = 'This is a required field';

    return newErrors;
   }

  const saveForm = () => {
    const newErrors = findErrors();
    if ( Object.keys(newErrors).length > 0 ) {
      setErrors(newErrors)
      toast.error('Enter the required details to proceed', toastAttr);
    } else if(form.password !== form.confirm_password) 
      toast.error('Password and Confirm Password must be same', toastAttr);
      else {
      setLoader(true);
        let data=JSON.parse(JSON.stringify(form));
        data.user_id = JSON.parse(sessionStorage.getItem('permission'))[0].user_id
        delete data['confirm_password']
        axios({
            url: config.service+'/accounts/user/change-password/',
            method: 'post',
            data: data,
            headers: {Authorization:'Token '+sessionStorage.getItem('tokenKey')},
          }).then(res=>{
              setLoader(false);
              toast.success('Password changed successfully', toastAttr);
              setTimeout(() =>  window.location.href = "/vendorportal", 1000);
        }).catch(err=>{
          if(err.response.status === 401) window.location.href = "/vendorportal"
          else
            toast.error('Password Changing Failed', toastAttr);
            setLoader(false);
        })
    }
  }

  const setLoader = (val) => {
    setloading(val);setoverlay(val);
  }

  const handleDialog = () => {
    setopenDialog(!openDialog);
  }


  return (
    <>
    <Dialog open={true} onClose={props.handleClose} maxWidth='md'>
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent>
          <TextField value={sessionStorage.getItem('userName')}
            margin="dense" disabled
            id="username"
            label="Username"
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField onChange={e=> handleInput('password',e.target.value)}
            autoFocus required
            margin="dense"
            id="password"
            label="New Password"
            type="password"
            fullWidth
            variant="standard"
          />
          <TextField onChange={e=> handleInput('confirm_password',e.target.value)}
             required
            margin="dense"
            id="confirm_password"
            label="Confirm Password"
            type="password"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={saveForm}>Save</Button>
          <Button onClick={props.handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>

  <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
        </>
);
};

ChangePassword.propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
  };

export default ChangePassword;
