import React from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import {FaChartArea,FaUsers,FaHome,FaRegCalendarAlt,FaMoneyBillWave,FaMoneyCheck,FaCalendarCheck} from 'react-icons/fa'

const SidebarContent = ({
  onClick,
  changeToDark,
  changeToLight,
  sidebarCollapse,
}) => {
  let role = sessionStorage.getItem('permission') ? JSON.parse(sessionStorage.getItem('permission'))[0].user_role : 'agent';
  return (
  <div className="sidebar__content">
    <ul className="sidebar__block">
    <SidebarLink title="Dashboard" icon={<FaChartArea />} route="/dashboard" onClick={onClick} />
      <SidebarLink title="Bookings" icon={<FaRegCalendarAlt />} route="/booking" onClick={onClick} />
      <SidebarLink title="Mandap" icon={<FaHome />} route="/mandapList" onClick={onClick} />
      <SidebarLink title="Update Dates" icon={<FaCalendarCheck />} route="/updateDates" onClick={onClick} />
      <SidebarLink title="Payments" icon={<FaMoneyCheck />} route="/ViewPayment" onClick={onClick} />
      <SidebarLink title="Cashback" icon={<FaMoneyBillWave />} route="/cashback" onClick={onClick} />

    </ul>
    {/* <ul className="sidebar__block">
      <SidebarCategory title="Example Pages" icon="diamond" sidebarCollapse={sidebarCollapse}>
        <SidebarLink title="Page one" route="/pages/one" onClick={onClick} />
        <SidebarLink title="Page two" route="/pages/two" onClick={onClick} />
        <SidebarCategory title="Example Subpages" sidebarCollapse={sidebarCollapse}>
          <SidebarLink title="Subpage one" route='/' onClick={onClick} />
          <SidebarLink title="Subpage two" route='/' onClick={onClick} />
        </SidebarCategory>
      </SidebarCategory>
    </ul> */}
  </div>
)};

SidebarContent.propTypes = {
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  sidebarCollapse: PropTypes.bool,
};

SidebarContent.defaultProps = {
  sidebarCollapse: false,
};

export default SidebarContent;
