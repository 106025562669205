import React ,{useState,useEffect} from 'react';
import { Col, Container, Row, Card,CardBody,Modal,ModalHeader,ModalBody,ModalFooter } from 'reactstrap';
import { Button } from 'react-bootstrap';
import { useHistory,Link } from 'react-router-dom';
import {FiEdit2} from 'react-icons/fi'
import Search from '../../Helper/CustomSearch'
import axios,{get} from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import config from '../../../config'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from "react-bootstrap-table2-paginator";
import Breadcrumbs from '../../Helper/Breadcrumbs'


const Cashback = (props) => {
  let history = useHistory();
  let tokenKey = {headers:{Authorization:'Token '+sessionStorage.getItem('tokenKey')}};
  let propsState = props.history.location.state;
  let bookingId =  props.location && props.location.state && props.location.state.bookingId;
  let vendorId = JSON.parse(sessionStorage.getItem('permission'))[0].vendor_ids[0];
  let breadcrumbData = bookingId ? props.location.state.breadcrumbData : [
    {
      title : 'Cashback',
      href : '#'
    }
  ]
  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    };
  const [tableData,settableData] = useState([]);

  const cols = [
    {
      dataField : 'vendor_name',
      text:'Vendor Name',
      headerStyle : () => { return{width : '200px'}}
    },
    {
        dataField : 'booking_no',
        text:'Booking Number',
        headerStyle : () => { return{width : '200px'}}
      },
      {
        dataField : 'amount',
        text:'Amount',
        headerStyle : () => { return{width : '200px'}}
      },
    // {
    //   dataField : 'id',
    //   text:'Actions',
    //   headerStyle : () => { return{width : '120px'}},
    //   events : {
    //     OnClick : e=>{return e.stopPropagation(); }
    //   },
    //   formatter:(cell,row)=>{
    //     return(
    //     <div className='tableActions'>
    //       <React.Fragment>
    //         <Link to={{pathname:"/masters/cityForm",state:{id:row.id}}}>
    //           <FiEdit2 title='Edit' />
    //         </Link>
    //       </React.Fragment>
    //     </div>)
    //   }
    // }
  ];

//   const renderForm = (row) => {
//       if(row) history.push({
//           pathname:"/masters/cityForm",
//           state : {
//             id: row.id,
//             // vendor_id : propsState.id,
//           editMode:true,
//           }
//       })
//     else history.push({
//         pathname:"/masters/cityForm",
//         state : {
//         // vendor_id : propsState.id,
//         }
//     })
//   }

  const renderOnSearch = searchTerm => {
    let url = '/vendor/cashback/?&vendor='+vendorId+'&search='+searchTerm;
    if(bookingId) url += '&booking='+bookingId;
    get(config.service+url,tokenKey).then(res=>{
      settableData(res.data);
    }).catch(err=>{
      if(err.response.status === 401) window.location.href = '/'
      toast.error('Search Failed', toastAttr);
    })
  }

  const rowEvents = {
    // onClick : (e,row) =>{
    //   history.push({
    //     pathname:"/masters/cityForm",
    //     state : {
    //       id: row.id,
    //     editMode:true,
    //     }
    // })
    // }
  }

  useEffect(() => {
    let url = '/vendor/cashback/?&vendor='+vendorId;
    if(bookingId) url += '&booking='+bookingId;
    get(config.service+url,tokenKey).then(res=>{
      settableData(res.data);
    }).catch(err=>{ 
      if(err.response.status === 401) window.location.href = '/'})
  }, []);

  let options = paginationFactory({
    sizePerPage: 20,
    paginationSize:3,
    hideSizePerPage:true,
    hidePageListOnlyOnePage : true,
    totalSize:tableData.length
  })

  return (
  <Container className="vendor">
    <Row>
  <Col md={12} className='contBody'>
    <Card>
      <CardBody>
        <div className="card__title pageNav">
        <div className="bold-text">
          <Breadcrumbs data={breadcrumbData} />
          </div>
        </div>
        <Row className='formBtnsDiv mar-b-10'>
  <Col md={8}>
    {/* <Button variant='primary' onClick={e=>renderForm()}>Create</Button> */}
    {/* <Button variant='primary' onClick={e=>setShowModal(true)}>View</Button> */}
  </Col>
  <Col md={4}>
    <Search callbackFn={e=>renderOnSearch(e)} />
  </Col>
        </Row>
        <Row className='vendorList'>
            <BootstrapTable  bootstrap4  wrapperClasses='table-responsive  pad-20' data={tableData} columns={cols} 
    keyField='id' containerStyle={{width:'100%'}} hover noDataIndication='No data found' rowEvents={rowEvents}
    pagination={options} />
        </Row>
      </CardBody>
    </Card>
  </Col>
  </Row>
  <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
  </Container>
);
};

export default Cashback;