import React ,{useState,useEffect} from 'react';
import { Col, Container, Row, Card,CardBody,Modal,ModalHeader,ModalBody,ModalFooter } from 'reactstrap';
import { Button } from 'react-bootstrap';
import { useHistory,Link  } from 'react-router-dom';
import {HiPencilAlt, HiOutlineTrash,HiOutlineCalendar} from 'react-icons/hi'
import Search from '../../../Helper/CustomSearch'
import axios,{get} from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import config from '../../../../config'
import {FaRegCalendarAlt,FaTable,FaListUl, FaEdit,FaCalendarCheck, FaCalendarAlt} from 'react-icons/fa';
import ViewCalender from './ViewCalender';
import Breadcrumbs from '../../../Helper/Breadcrumbs'
import Loader from '../../../Helper/Loader';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from "react-bootstrap-table2-paginator";
import {Pagination} from '@mui/material'
import empty from 'is-empty'

const icon = `${process.env.PUBLIC_URL}/img/booking.png`;

const Booking = (props) => {
  let history = useHistory();
  let tokenKey = {headers:{Authorization:'Token '+sessionStorage.getItem('tokenKey')}};
  let propsState = props.history.location.state;
  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    };
  const [tableData,settableData] = useState([]);
  const [showModal, setShowModal] = useState(false) 
  const [loading, setloading] = useState(true);
  const [view, setview] = useState('list');
  const [searchTerm, setsearchTerm] = useState(null);
  const [page, setpage] = useState({page : 1, count : 1,total:0, next: null,previous: null});


  let mapStatus = {
    'draft' : 'Draft',
    'booking_confirmed' : 'Booking Confirmed',
    voucher_issued : 'Voucher Issued',
    voucher_redeemed : 'Voucher Redeemed',
    encashed : 'Encashed',
    cancelled : 'Cancelled'
  }

  const setLoader = (val) => {
    setloading(val);
  }

  let breadcrumbData =   propsState && propsState.filterVal ? [
    {
      title : 'Dashboard',
      href : '/dashboard'
    },{
      title : 'Bookings',
      href : '#'
    }
  ] : [
    {
      title : 'Bookings',
      href : '#'
    }
  ]
  const toggleModal = () => {
    setShowModal(!showModal);
}
  const renderForm = (row) => {
      if(row) history.push({
          pathname:'/bookingForm',
          state : {
            id: row.id,
            // vendor_id : propsState.id,
          editMode:true,
          }
      })
    else history.push({
        pathname:'/bookingForm',
        state : {
        // vendor_id : propsState.id,
        }
    })
  }

  const cols = [
    {
      dataField : 'customer_name',
      text:'Customer Name',
      headerStyle : () => { return{width : '180px'}}
    },
    {
      dataField : 'mobile',
      text:'Mobile',
      headerStyle : () => { return{width : '150px'}}
    },
    {
      dataField : 'mandap_name',
      text:'Mandap Name ',
      headerStyle : () => { return{width : '250px'}}
    },
    {
      dataField : 'mandap_function_type_name',
      text:'Function Type',
      headerStyle : () => { return{width : '120px'}}
    },
    {
      dataField : 'mobile',
      text:'Mobile',
      headerStyle : () => { return{width : '120px'}}
    },
    {
      dataField : 'booking_from',
      text:'Booking From',
      headerStyle : () => { return{width : '200px'}},
      formatter : (cell,row) => {
        let fromDate = row.booking_from ? row.booking_from.replace('T',' ').replace('Z','') : '';
        return (<div>{fromDate}</div>)
      }
    },{
      dataField : 'booking_to',
      text:'Booking To',
      headerStyle : () => { return{width : '200px'}},
      formatter : (cell,row) => {
        let toDate = row.booking_to ? row.booking_to.replace('T',' ').replace('Z','') : ''
        return (<div>{toDate}</div>)
      }
    },
    // {
    //   dataField : 'status',
    //   text:'Status',
    //   headerStyle : () => { return{width : '120px'}},
    //   formatter : (cell) => {return(<div>{mapStatus[cell] || cell}</div>)}
    // },
    {
      dataField : 'id',
      text:'Actions',
      headerStyle : () => { return{width : '60px'}},
      events : {
        OnClick : e=>{return e.stopPropagation(); }
      },
      formatter:(cell,row)=>{
        return(
        <div className='tableActions'>
          <React.Fragment>
            <Link to={{pathname:"/bookingForm",state:{id:row.id, editMode:true}}}>
              <FaEdit title='Edit' className='color-green' />
            </Link>
          </React.Fragment>
        </div>)
      }
    }
  ];

  const rowEvents = {
    onClick : (e,row) =>{
      history.push({
        pathname:"/bookingForm",
        state : {
          id: row.id,
        editMode:true,
        }
    })
    }
  }

  let options = paginationFactory({
    page:page.page,
    sizePerPage: 20,
    paginationSize:3,
    hideSizePerPage:true,
    hidePageListOnlyOnePage : true,
    totalSize:page.total
  })

  const manipulateData = (res,pageNum) => {
    let count =  Math.ceil(res.data.count/20)
    setpage({page:pageNum || page.page, count:count,total:res.data.count, next:res.data.next,previous:res.data.previous});
    settableData(res.data.results);
    setLoader(false);
  }


  const handlePagination = (e, newPage) => {
    setLoader(true);
    let url = config.service+'/booking/list/?&page='+newPage;
    if(!empty(searchTerm)) url += '&search='+searchTerm;
    get(url,tokenKey).then(res=>{
      manipulateData(res,newPage);
      setpage({...page, page:newPage});
      window.scrollTo({
        top: 0,
        behavior: 'instant',
      })
    }).catch(err => {
      setLoader(false);
    })
  }

  const handleTablePagination = (type,{page}) => {
    if(type === 'pagination'){
      setLoader(true);
      let url = config.service+'/booking/list/?&page='+page;
      if(!empty(searchTerm)) url += '&search='+searchTerm;
      get(url,tokenKey).then(res=>{
        manipulateData(res,page);
        window.scrollTo({
          top: 0,
          behavior: 'instant',
        })
      }).catch(err => {
        setLoader(false);
      })
    }
  }


  const renderOnSearch = searchTerm => {
    setLoader(true)
    let url = config.service+'/booking/list/?&search='+searchTerm;
    if(propsState && propsState.filterVal){
      let param = '&status='+propsState.filterVal;
      url += param;
    }
    get(url,tokenKey).then(res=>{
      setsearchTerm(searchTerm)
      manipulateData(res,1)
    }).catch(err=>{
      if(err.response.status === 401) window.location.href = '/'; setLoader(false);
      toast.error('Search Failed', toastAttr);
    })
  }

  useEffect(() => {
    let url = config.service+'/booking/list/';
    if(propsState && propsState.filterVal){
      let param = 'status='+propsState.filterVal;
      url = config.service+'/booking/list/?&'+param;
    }
    get(url,tokenKey).then(res=>{
      manipulateData(res,1)
    }).catch(err=>{ setLoader(false);if(err.response&& err.response.status === 401) window.location.href = '/'})
  }, []);

  return (
  <Container className="vendor">
    <Row>
  <Col md={12} className='contBody'>
    <Card>
      <CardBody>
        <div className="card__title pageNav">
          <div className="bold-text">
          <Breadcrumbs data={breadcrumbData} />
          </div>
        </div>
        <Row className='formBtnsDiv mar-b-10'>
  <Col md={8}>
    <Button variant='primary' onClick={e=>renderForm()}>Create</Button>
    <span title='View All Bookings' className='calIcon' onClick={e=>setShowModal(true)}><FaRegCalendarAlt /></span>
  </Col>
  {/* <Col md={1} className='calIcon'>
    <span onClick={e=>setShowModal(true)}><FaRegCalendarAlt /></span>
  </Col> */}
  <Col md={4}>
    <Search callbackFn={e=>renderOnSearch(e)} />
  </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className='viewIcons'>
              <span><FaListUl title='Card View' onClick={()=>{setview('list')}} /></span>
              <span><FaTable title='List View' onClick={()=>{setview('table')}} /></span>
            </div>
          </Col>
        </Row>
        <Row className='vendorList'>
        <Loader loading={loading}>
        {view === 'list' ? 
            
      tableData.length>0 ? tableData.map(row=>{
        let fromDate = row.booking_from ? row.booking_from.replace('T',' ').replace('Z','') : null, toDate = row.booking_to ? row.booking_to.replace('T',' ').replace('Z','') : null;
                return (
                    <Col md={4} className='divCont'>
                        <div className='child' onClick={e=>renderForm(row)}>
                        <img className="bookingImg" src={icon} alt="Booking" />
                          </div>
                        <div className='child' onClick={e=>renderForm(row)}>
                            <div className='header'> {row.customer_name}</div>
                            <div><span>Booking Number : </span>{row.booking_no}</div>
                            <div><span>Mobile : </span>{row.mobile}</div>
                            <div><span>Mandap : </span>{row.mandap_name}</div>     
                            <div><span>Funtion :</span> {row.mandap_function_type_name}</div>
                           {fromDate? <div><span>Booking From:</span>{new Date(fromDate).toLocaleString()}</div> : ''}
                           {toDate ? <div><span>Booking To:</span>{new Date(toDate).toLocaleString()}</div> : ''}
                            {row.booking_date ? <div><span>Booking Date : </span>{row.booking_date}</div> : '' }
                            <div><span>Status : </span>{mapStatus[row.status] || row.status}</div>
                        </div>
                        <div className='child'>
                            <HiPencilAlt className='color-green actionIcon' onClick={e=> renderForm(row)}/>
                            {/* <HiOutlineTrash className='themeColor actionIcon' onClick={e => deleteRow(row) } /> */}
                        </div>
                    </Col>
                )
            }) : <div className='textCenter'>No data found</div> : 
            <BootstrapTable remote bootstrap4  wrapperClasses='table-responsive  pad-20' data={tableData} columns={cols} 
            keyField='id' containerStyle={{width:'100%'}} hover noDataIndication='No data found' rowEvents={rowEvents}
            pagination={options} onTableChange={handleTablePagination} />}
{view === 'list' && !empty(tableData) ? <Row className='common-pagination'>
        <Col md={6} />
        <Col md={6}>
        <Pagination className='paginate' count={page.count} page={page.page} onChange={handlePagination} color='primary' />
        </Col>
      </Row> : ''}
            </Loader>
            <Col md={6}></Col>
        </Row>
        <Modal toggle={e=>{toggleModal()}} isOpen={showModal} className='viewCalender' >
          {/* <ModalHeader
            toggle={e=>{toggleModal()}}
          >
            View Bookings
          </ModalHeader> */}
          <ModalBody>
            <div className='viewCalCont'>
             <ViewCalender data={tableData} />   
             </div>     
         </ModalBody>
        </Modal>
      </CardBody>
    </Card>
  </Col>
  </Row>
  <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
  </Container>
);
};

export default Booking;
