import React,{ useState,useEffect} from "react";
import { Container,Col,Row,Card,CardBody } from "reactstrap";
import { Button,Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axios,{post,get,patch} from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import config from '../../../config';
import Loader from '../../Helper/Loader'
import empty from 'is-empty'
import Select from 'react-select'
import {BiRupee} from 'react-icons/bi'
import {FaTrash,FaEdit} from 'react-icons/fa';
import BootstrapTable from 'react-bootstrap-table-next';

const OtherPricing = props => {
    let tokenKey = {headers:{Authorization:'Token '+sessionStorage.getItem('tokenKey')}};
    const [typeList, setTypeList] = useState([]);
    const [type, setType] = useState(null);
    const [price, setPrice] = useState(null);
    const [data, setData] = useState([]);
    const [currIndex, setcurrIndex] = useState(null);
    const [check, setcheck] = useState(0)
    const [loading, setloading] = useState(true);
    const [overlay, setoverlay] = useState(false);

    const setLoader = (val) => {
        setloading(val);setoverlay(val);
      }

    useEffect(() => {
        get(config.service+'/masters/mandapam/function-type/',tokenKey).then(res=>{
            let typeList = [];
            res.data.map(obj=>{
                typeList.push({label:obj.function_type,value:obj.id})
            })
            get(config.service+'/masters/mandap-functiontype-pricing/'+props.mandapId,tokenKey).then(res=>{
                let priceList = [], values=[];
                res.data.map(obj=>{
                    priceList.push(obj.price)
                })
                priceList = [...new Set(priceList)];
                priceList.map(price=>{
                    let data = res.data.filter(obj=>obj.price === price);
                    let types = data.map(a => a.function_type);
                    let typeLabel = ''
                    let typeObj = []
                    types.map((typeVal,indexVal)=>{
                        typeList.map((type,index)=>{
                            if(type.value === typeVal){
                                typeObj.push(type);
                                typeLabel += type.label;
                                if(indexVal < types.length-1) typeLabel += ', ';
                                typeList.splice(index,1)
                            }
                        })
                    })

                    values.push( {
                        function_type : typeLabel,
                        price : price,
                        typeObj: typeObj
                    })
                })
                setData(values);
                setTypeList(typeList);
            setLoader(false); 
            })
        }).catch(err=>{
            setLoader(false); 
      if(err.response.status === 401) window.location.href = '/'
        })
    }, []);
    const handleSelect = (keyName,setFn,e) =>{
        setFn(e || null);
    }

    const handleInput = (keyName, val) => {
        setPrice(val)
    }
    const addPrice = () => {
        setLoader(true)
        let types = '', list = typeList, tableData = data, tempObj=[];
        type.map((obj,index)=>{
            types += obj.label ;
            if(index < type.length-1) types += ', ';
            list = list.filter(val=>val.value !== obj.value);
            tempObj.push(obj)
        })
        if(currIndex) 
            tableData[currIndex-1] = {
                function_type : types,
                price : price,
                typeObj: type
            }
        else tableData.push({
            function_type : types,
            price : price,
            typeObj: type
        })
        setData(tableData)
        setTypeList(list);
        setType(null);
        setPrice('')
        setcurrIndex(null)
        props.updateData(tableData)
        setLoader(false)
    }

    const editRow = (index,row) => {
        setLoader(true)
        let tableData = data, list = typeList || [];
        Array.prototype.push.apply(list,row.typeObj)
        setData(tableData);
        setTypeList(list);
        setType(row.typeObj);
        setPrice(row.price);
        setcurrIndex(index+1);
        setLoader(false)
    }

    const removeRow = (index,row) => {
        setLoader(true)
        let tableData = data, list = typeList || [];
        tableData.splice(index,1);
        Array.prototype.push.apply(list,row.typeObj)
        setData(tableData);
        setTypeList(list);
        setcheck(check+1)
        props.updateData(tableData)
        setLoader(false)

    }

    const cols = [
        {
          dataField : 'function_type',
          text:'Function Type',
          headerStyle : () => { return{width : '800px'}}
        },
        {
          dataField : 'price',
          text:'Price',
          headerStyle : () => { return{width : '100px'}}
        },{
            dataField : 'id',
            text:'Actions',
            headerStyle : () => { return{width : '80px'}},
            events : {
              OnClick : e=>{return e.stopPropagation(); }
            },
            formatter:(cell,row,index)=>{
              return(
              <div className='tableActions'>
                    <FaEdit title='Edit' onClick={e=>editRow(index,row)}  />
                    <FaTrash title='Remove' onClick={e=>removeRow(index,row)} />
              </div>)
            }
          }
    ]

    return(
        <Loader loading={loading} overlay={overlay}>
        <Row>
            <Col md={6}>
                <Row>
                    <Col md={3}>
                        <label>Function Type</label>
                    </Col>
                    <Col md={9}>
                    <Select className='selectBox' isMulti
                        value={type}
                        onChange={e=>handleSelect('type',setType,e)}
                        options={typeList}
                        isClearable
                    />
                    </Col>
                </Row>
            </Col>
            <Col md={6}>
                <Row>
                    <Col md={3}>
                        <Form.Label>Price (<BiRupee size={15} />) </Form.Label>
                    </Col>
                    <Col md={9}>
                        <Form.Control type='number' name="price" value={price} onChange={e=> handleInput('price',e.target.value)} />
                    </Col>
                </Row>
            </Col>
        </Row>
        <Row className='formBtnsDiv'>
                <Col md={12} style={{textAlign:'center'}}>
                        <Button variant='primary' onClick={addPrice}>{currIndex ? 'Update' : 'Add'}</Button> 
                </Col>
            </Row>
            {!empty(data) ?
                <Row>
                    <Col md={12}>
                    <BootstrapTable  bootstrap4  wrapperClasses='table-responsive  pad-20' data={data} columns={cols} 
                    keyField='id' containerStyle={{width:'100%'}} hover noDataIndication='No data found' />
                    </Col>
                </Row> : ''}
                <span style={{display:'none'}}>{check}</span>
        </Loader>
    )
}

export default OtherPricing;