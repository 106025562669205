import React, { useState } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';
import { useHistory,Link } from 'react-router-dom';
import {FaUserAlt,FaKey} from 'react-icons/fa';
import ChangePassword from '../../LogIn/components/ChangePassword'

const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

const TopbarProfile = () => {
  let history = useHistory();
  let role = sessionStorage.getItem('permission') ? JSON.parse(sessionStorage.getItem('permission'))[0].user_role : 'agent';
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [showChangePwd, setshowChangePwd] = useState(false);
  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };
  const clearStorage = () => {
    // sessionStorage.clear()
  }

  const redirectToVendor = () => {
    history.push({
      pathname:'/profile',
      state : {
        id: JSON.parse(sessionStorage.getItem('permission'))[0].vendor_ids[0],
      }
  })
  }

  const redirectToChangePwd = () => {
    setshowChangePwd(true)
    // history.push('/changePassword')
  }

  return (
    <>
    <div className="topbar__profile">
      <button type="button" className="topbar__avatar" onClick={handleToggleCollapse}>
        <FaUserAlt className="topbar__avatar-img" color='white' />
        <p className="topbar__avatar-name">{sessionStorage.getItem('userName')}</p>
        <DownIcon className="topbar__icon" color='white' />
      </button>
      {isCollapsed && (
        <button
          type="button"
          aria-label="button collapse"
          className="topbar__back"
          onClick={handleToggleCollapse}
        />
      )}
      <Collapse isOpen={isCollapsed} className="topbar__menu-wrap">
        <div className="topbar__menu">
        <Link className="topbar__link">
            <FaUserAlt className={`topbar__link-icon`} />
            <p className="topbar__link-title" onClick={e=> redirectToVendor()}>Profile</p>
          </Link> 
          <Link className="topbar__link">
            <FaKey className={`topbar__link-icon`} />
            <p className="topbar__link-title" onClick={e=> redirectToChangePwd()}>Change Password</p>
          </Link> 
          <TopbarMenuLink title="Log Out" icon="exit" path='/' onClick={e=> clearStorage()} />
        </div>
      </Collapse>
    </div>
    {showChangePwd ? 
    <ChangePassword handleClose={e=>setshowChangePwd(false)} /> : ''}
    </>
  );
};

export default TopbarProfile;
