import React, { useState, useEffect } from "react";
import { Col, Label, Row } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  FormControlLabel,
  Box,
  Tabs,
  Tab,
  TextField,
  Backdrop,
  Paper,
  CircularProgress,
} from "@mui/material";
import { Button } from "react-bootstrap";
import axios from "axios";
import { get, post } from "axios";
import config from "../../../../config";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import { filter, includes } from "lodash";
import empty from "is-empty";
import { FaSquare } from "react-icons/fa";
import { MdClear } from "react-icons/md";
import { IoCheckmarkDoneCircleSharp } from "react-icons/io5";
import { makeStyles } from "@material-ui/core/styles";
import ConfirmationModel from "../../ConfirmationModal";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

const BlockDates = (props) => {
  const classes = useStyles();
  let tokenKey = {
    headers: { Authorization: "Token " + sessionStorage.getItem("tokenKey") },
  };
  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const [selDate, setselDate] = useState([]);
  const [showOptions, setshowOptions] = useState(false);
  const [optionVal, setoptionVal] = useState({
    half_day_morning: false,
    half_day_evening: false,
  });
  const [check, setcheck] = useState(0);
  const [resData, setresData] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [highlightArr, sethighlightArr] = useState([
    { "react-datepicker-day-am": [] },
    { "react-datepicker-day-pm": [] },
    { "react-datepicker-day-full": [] },
  ]);
  const [exclude, setexclude] = useState([]);
  const [initOption, setinitOption] = useState({});
  const [tabvalue, settabvalue] = React.useState(0);
  const [selectedUnavailableDatesfromApi, setselectedUnavailableDatesfromApi] =
    useState([]);
  const [initalselectedUnavailableDate, setinitalselectedUnavailableDate] =
    useState(null);
  const [openbackdrop, setopenbackdrop] = React.useState(true);
  const [openConfimationModal, setopenConfimationModal] = useState(false);
  const [selectedRow, setselectedRow] = useState(null);
  const [visitedDatescheckbox, setvisitedDatescheckbox] = useState(false);

  const handletabvalue = (event, newValue) => {
    settabvalue(newValue);
  };

  const getUnavailableDates = async () => {
    setopenbackdrop(true);
    const url = `${config.service}/vendor/AllDayAvailable/?id=${props.mandapId}`;
    await get(url, tokenKey)
      .then((res) => {
        setselectedUnavailableDatesfromApi(res?.data);
        setopenbackdrop(false);
      })
      .catch((err) => {
        console.log(err);
        setopenbackdrop(false);
      });
  };

  const setDefaultDates = (resData) => {
    let unavailable = [],
      full = [],
      partial = [],
      fullDates = [],
      am = [],
      pm = [],
      vendBlock = [],
      fullObj = {};
    resData = resData.filter((val) => val.is_blocked === true);
    resData.map((obj) => {
      fullDates.push(obj.date);
    });
    fullDates = filter(fullDates, (val, i, iteratee) =>
      includes(iteratee, val, i + 1)
    );
    resData.map((obj) => {
      if (fullDates.includes(obj.date)) {
        full.push({
          ...obj,
          state: "full",
        });
        if (obj.date in fullObj) {
          fullObj[obj.date].push(obj);
        } else fullObj[obj.date] = [obj];
      } else
        partial.push({
          ...obj,
          state: "partial",
        });
    });
    Object.keys(fullObj).map((date) => {
      let isUnavailable = [],
        vendorBlock = [];
      fullObj[date].map((obj) => {
        if (obj.blocked_by === "customer") isUnavailable.push(true);
        else if (obj.blocked_by === "vendor") vendorBlock.push(true);
      });
      if (isUnavailable.includes(false))
        Array.prototype.push.apply(partial, fullObj[date]);
      else if (!empty(isUnavailable)) unavailable.push(new Date(date));
      if (vendorBlock.includes(false))
        Array.prototype.push.apply(partial, fullObj[date]);
      else if (!empty(vendorBlock)) vendBlock.push(new Date(date));
    });
    partial.map((obj) => {
      if (obj.timing === "half_day_morning") am.push(new Date(obj.date));
      else if (obj.timing === "half_day_evening") pm.push(new Date(obj.date));
    });
    let highlight = [
      { "react-datepicker-day-am": am },
      { "react-datepicker-day-pm": pm },
      { "react-datepicker-day-full": vendBlock },
    ];
    sethighlightArr(highlight);
    setexclude(unavailable);
    setcheck(check + 1);
    setresData(resData);
  };

  const handleChange = (newDate) => {
    let temp = highlightArr;
    let full = temp[2]["react-datepicker-day-full"],
      am = temp[0]["react-datepicker-day-am"],
      pm = temp[1]["react-datepicker-day-pm"],
      options = { half_day_morning: false, half_day_evening: false };
    newDate.setHours(0, 0, 0, 0);
    let isFull = full.find(
        (date) => date.toDateString() === newDate.toDateString()
      ),
      isAM = am.find((date) => date.toDateString() === newDate.toDateString()),
      isPM = pm.find((date) => date.toDateString() === newDate.toDateString());
    if (isFull) options = { half_day_morning: true, half_day_evening: true };
    else if (isAM)
      options = { half_day_morning: true, half_day_evening: false };
    else if (isPM)
      options = { half_day_morning: false, half_day_evening: true };
    setStartDate(newDate);
    setoptionVal(options);
    setcheck(check + 1);
    setinitOption(options);
    setshowOptions(true);
  };

  const proceed = () => {
    let dates = selDate,
      temp = highlightArr,
      formattedDate = moment(startDate).format("YYYY-MM-DD"),
      full = temp[2]["react-datepicker-day-full"],
      am = temp[0]["react-datepicker-day-am"],
      pm = temp[1]["react-datepicker-day-pm"];
    checkDiff();
    if (!optionVal.half_day_morning)
      am = am.filter((val) => val.toDateString() !== startDate.toDateString());
    if (!optionVal.half_day_evening)
      pm = pm.filter((val) => val.toDateString() !== startDate.toDateString());

    if (optionVal.half_day_morning && optionVal.half_day_evening) {
      am = am.filter((val) => val.toDateString() !== startDate.toDateString());
      pm = pm.filter((val) => val.toDateString() !== startDate.toDateString());
      if (!full.includes(startDate)) full.push(startDate);
      dates.map((obj, index) => {
        if (
          obj.date === formattedDate &&
          (obj.timing === "half_day_morning" ||
            obj.timing === "half_day_evening")
        )
          dates.splice(index, 1);
      });
      dates.push(
        {
          timing: "half_day_morning",
          is_blocked: true,
          date: formattedDate,
        },
        {
          timing: "half_day_evening",
          is_blocked: true,
          date: formattedDate,
        }
      );
      // checkDiff()
    } else if (optionVal.half_day_morning) {
      full = full.filter(
        (val) => val.toDateString() !== startDate.toDateString()
      );
      let isPresent = pm.find(
        (date) => date.toDateString() === startDate.toDateString()
      );
      if (isPresent) full.push(startDate);
      else if (!am.includes(startDate)) am.push(startDate);
      pm = pm.filter((val) => val.toDateString() !== startDate.toDateString());
      dates.map((obj, index) => {
        if (obj.date === formattedDate && obj.timing === "half_day_morning")
          dates.splice(index, 1);
      });
      dates.push({
        timing: "half_day_morning",
        is_blocked: true,
        date: formattedDate,
      });
      // checkDiff()
    } else if (optionVal.half_day_evening) {
      full = full.filter(
        (val) => val.toDateString() !== startDate.toDateString()
      );
      let isPresent = am.find(
        (date) => date.toDateString() === startDate.toDateString()
      );
      if (isPresent) full.push(startDate);
      else if (!pm.includes(startDate)) pm.push(startDate);
      am = am.filter((val) => val.toDateString() !== startDate.toDateString());
      dates?.map((obj, index) => {
        if (obj.date === formattedDate && obj.timing === "half_day_evening")
          dates.splice(index, 1);
      });
      dates.push({
        timing: "half_day_evening",
        is_blocked: true,
        date: formattedDate,
      });
      // checkDiff()
    } else {
      am = am.filter((val) => val.toDateString() !== startDate.toDateString());
      pm = pm.filter((val) => val.toDateString() !== startDate.toDateString());
      full = full.filter(
        (val) => val.toDateString() !== startDate.toDateString()
      );
      // checkDiff();
    }

    function checkDiff() {
      let diff = Object.keys(initOption).filter(
        (k) => initOption[k] !== optionVal[k]
      );
      if (diff)
        diff.map((diffval) => {
          dates.map((obj, index) => {
            if (obj.date === formattedDate && obj.timing === diffval)
              dates.splice(index, 1);
          });
          if (initOption[diffval])
            dates.push({
              timing: diffval,
              is_blocked: false,
              date: formattedDate,
            });
        });
    }

    let highlight = [
      { "react-datepicker-day-am": am },
      { "react-datepicker-day-pm": pm },
      { "react-datepicker-day-full": full },
    ];
    setselDate(dates);
    sethighlightArr(highlight);
    setcheck(check + 1);
    // setStartDate(null)
    handleOptionClose();
  };

  const saveDate = () => {
    if (!empty(selDate)) {
      let postData = {
        allocated_dates: selDate,
        booking_id: null,
      };
      post(
        config.service + "/vendor/mandap-allocation/" + props.mandapId + "/",
        postData,
        tokenKey
      )
        .then((res) => {
          let skipped = "";
          if (res.data.data) setDefaultDates(res.data.data);
          if (res.data.skipped_dates)
            res.data.skipped_dates.map((obj) => {
              skipped += obj.date + ", ";
            });
          setselDate([]);
          if (res.data.message)
            toast.info(skipped + "are skipped due to " + res.data.message, {
              ...toastAttr,
              autoClose: 5000,
            });
          else toast.success("Mandap Allotted successfully", toastAttr);
          //setStartDate(new Date())
        })
        .catch((err) => {
          let skipped = "",
            resp = err.response && err.response.data;
          if (resp.data) setDefaultDates(resp.data);
          if (resp.skipped_dates)
            resp.skipped_dates.map((obj) => {
              skipped += obj.date + ", ";
            });
          setselDate([]);
          if (resp.message)
            toast.info(skipped + "are skipped as " + resp.message, {
              ...toastAttr,
              autoClose: 7000,
            });
          else toast.error("Allocation failed", toastAttr);
          if (err.response.status === 401) window.location.href = "/";
        });
    }
  };
  const handleOptionClose = () => {
    setoptionVal({ half_day_morning: false, half_day_evening: false });
    setshowOptions(false);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleClose = () => {
    setopenbackdrop(false);
  };

  const formatDate = (inputDate) => {
    let formatDate = inputDate.split(" ");
    let actualDate = formatDate[0];
    return actualDate;
  };

  const handleselectedUnavailableDates = (choosedDate) => {
    setinitalselectedUnavailableDate(choosedDate);
  };

  const postUnavailableDates = async () => {
    setopenbackdrop(true);
    let postData = {
      mandap_id: props.mandapId,
      all_dates: initalselectedUnavailableDate,
      all_day_available: visitedDatescheckbox,
    };
    const url = `${config.service}/vendor/AllDayAvailable/`;
    await post(url, postData, tokenKey)
      .then((response) => {
        console.log("Response:", response.data);
        toast.success("Date Successfully Added", toastAttr);
        setopenbackdrop(false);
        setinitalselectedUnavailableDate("");
        setvisitedDatescheckbox(false);
        getUnavailableDates();
      })
      .catch((error) => {
        console.error("Error:", error);
        setopenbackdrop(false);
        toast.error(`Date Adding Failed ${error}`, toastAttr);
      });
  };

  const handleremoveUnavailableDates = () => {
    setopenConfimationModal(true);
  };

  const deleteSelectedUnavailableDates = () => {
    setopenbackdrop(true);
    const url = `${config.service}/vendor/AllDayAvailable/?id=${selectedRow}`;
    axios
      .delete(url, tokenKey)
      .then(function (response) {
        console.log(response, "successfully deleted");
        toast.success("Date Deleted Successfully", toastAttr);
        getUnavailableDates();
      })
      .catch(function (error) {
        console.log(error);
        toast.error("Failed", toastAttr);
      });
  };

  useEffect(() => {
    getUnavailableDates();
  }, []);

  useEffect(() => {
    get(
      config.service + "/vendor/mandap-allocation/" + props.mandapId + "/",
      tokenKey
    )
      .then((res) => {
        setDefaultDates(res.data);
      })
      .catch((err) => {
        // if (err.response.status === 401) window.location.href = "/";
        console.log(err);
      });
  }, []);

  return (
    <>
      <Dialog
        open={true}
        onClose={handleClose}
        maxWidth="lg"
        className="allocateMandap"
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tabvalue}
            onChange={handletabvalue}
            aria-label="basic tabs example"
          >
            <Tab label="Block / Unblock Bookings" {...a11yProps(0)} />
            <Tab label="All Dates Available" {...a11yProps(1)} />
          </Tabs>
        </Box>

        {tabvalue === 0 && (
          <DialogContent className="date-modal">
            <div id="date-popup"></div>
            <DatePicker
              getPopupContainer={() => document.getElementById("date-popup")}
              popupStyle={{
                position: "relative",
              }}
              allowSameDay={true}
              open={true}
              showNow={false}
              selected={startDate}
              onChange={handleChange}
              highlightDates={highlightArr}
              placeholderText=""
              excludeDates={exclude}
              //   onSelect={handleSelect}
              shouldCloseOnSelect={false}
              monthsShown={1}
              minDate={new Date()}
            />

            {showOptions ? (
              <Dialog
                open={showOptions}
                onClose={() => setshowOptions(false)}
                maxWidth="md"
              >
                <DialogTitle>Pick a slot </DialogTitle>
                <DialogContent className="slotCont">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={optionVal.half_day_morning}
                          onChange={(e) =>
                            setoptionVal({
                              ...optionVal,
                              half_day_morning: e.target.checked,
                            })
                          }
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Morning half"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={optionVal.half_day_evening}
                          onChange={(e) =>
                            setoptionVal({
                              ...optionVal,
                              half_day_evening: e.target.checked,
                            })
                          }
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Evening half"
                    />
                  </FormGroup>
                </DialogContent>
                <DialogActions>
                  <Button onClick={proceed}>Proceed</Button>
                  <Button onClick={handleOptionClose}>Cancel</Button>
                </DialogActions>
              </Dialog>
            ) : (
              ""
            )}
            <span style={{ display: "none" }}>{check}</span>

            <Row className="legends">
              <Col md={6} xs={12}>
                <FaSquare color="#F6C586" /> Morning half blocked
              </Col>
              <Col md={6} xs={12}>
                <FaSquare color="#9BE079" /> Evening half blocked
              </Col>
              <Col md={6} xs={12}>
                <FaSquare color="#bbb" /> Full day blocked
              </Col>
            </Row>
          </DialogContent>
        )}

        {tabvalue === 1 && (
          <DialogContent
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              scrollBehavior: "auto",
              overflowY: "auto",
              overflow: "visible",
              width: "500px",
            }}
          >
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={openbackdrop}
              onClick={handleClose}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <div className="d-flex direction-column justify-space-between align-items-center">
              <div className="d-flex justify-content-start align-items-center">
                <Checkbox
                  checked={visitedDatescheckbox}
                  onChange={(e) => {
                    setvisitedDatescheckbox(e.target.checked);
                  }}
                />
                <Label className="mt-2">All Dates Available</Label>
              </div>

              <TextField
                id="date"
                name="unavailabledate"
                label="Visited Dates"
                value={initalselectedUnavailableDate}
                type="date"
                size="small"
                width="auto"
                className={classes.textField}
                onChange={(e) => handleselectedUnavailableDates(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ marginLeft: "20px" }}
                disabled={!visitedDatescheckbox}
              />

              <IoCheckmarkDoneCircleSharp
                className="m-2"
                size={20}
                color="green"
                style={{ cursor: "pointer" }}
                onClick={() => postUnavailableDates()}
              />
            </div>

            <Row>
              <Col
                md={12}
                style={{
                  padding: "10px",
                  marginTop: "10px",
                }}
              >
                {selectedUnavailableDatesfromApi?.length > 0 &&
                  selectedUnavailableDatesfromApi?.map((date, index) => (
                    <Row key={index} className="mt-2">
                      <Col md={12}>
                        <Paper className="p-1 mb-2">
                          <div className="d-flex justify-content-start align-items-center">
                            <div className="d-flex justify-content-start align-items-center w-75">
                              <Checkbox
                                checked={date?.all_day_available}
                                onClick={() =>
                                  toast.error(
                                    "Edit opeartion not allowed",
                                    toastAttr
                                  )
                                }
                              />
                              <Label className="mt-2">
                                All Dates Available
                              </Label>
                            </div>
                            <TextField
                              id={date.id}
                              name="unavailabledate"
                              size="small"
                              value={formatDate(date?.all_dates)}
                              type="text"
                              className={classes.textField}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />

                            <MdClear
                              className="mt-1 ml-2"
                              size={20}
                              style={{ cursor: "pointer" }}
                              color="red"
                              onClick={() => {
                                handleremoveUnavailableDates();
                                setselectedRow(date?.id);
                              }}
                            />
                          </div>
                        </Paper>
                      </Col>
                    </Row>
                  ))}
              </Col>
            </Row>
          </DialogContent>
        )}

        {tabvalue === 0 && (
          <DialogActions>
            <Button onClick={saveDate}>Save Changes</Button>
            <Button onClick={props.handleClose}>Close</Button>
          </DialogActions>
        )}
      </Dialog>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <ConfirmationModel
        operation={"Remove"}
        handleConfirmation={() => {
          setopenConfimationModal(false);
          deleteSelectedUnavailableDates();
        }}
        val={openConfimationModal}
        modelName={"Date"}
        onclose={() => {
          setopenConfimationModal(false);
        }}
      />
    </>
  );
};
export default BlockDates;
