import React ,{useState,useEffect} from 'react';
import { Col, Container, Row, Card,CardBody, Collapse } from 'reactstrap';
import { Button } from 'react-bootstrap';
import { useHistory,Link } from 'react-router-dom';
import {FaUserAlt,FaTable,FaListUl, FaEdit,FaTimes} from 'react-icons/fa'
import {HiPencilAlt, HiOutlineTrash} from 'react-icons/hi'
import {BiFilterAlt} from 'react-icons/bi'
import Search from '../../Helper/CustomSearch'
import axios,{post,get} from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import config from '../../../config'
import Loader from '../../Helper/Loader'
import Breadcrumbs from '../../Helper/Breadcrumbs'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from "react-bootstrap-table2-paginator";
import {Pagination} from '@mui/material'
import empty from 'is-empty'
import {Dialog, DialogActions,DialogContent, DialogContentText,DialogTitle} from '@mui/material'

const icon = `${process.env.PUBLIC_URL}/img.png`;

const Vendor = (props) => {
  let history = useHistory();
  let tokenKey = {headers:{Authorization:'Token '+sessionStorage.getItem('tokenKey')}};
  let propsState = props.location.state ||  {};
  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    };
  const [tableData,settableData] = useState([]);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [currentURL, setCurrentURL] = useState(null);
  const [loading, setloading] = useState(true);
  const [overlay, setoverlay] = useState(false);
  const [view, setview] = useState('list');
  const [searchTerm, setsearchTerm] = useState(null);
  const [page, setpage] = useState({page : 1, count : 1,total:0, next: null,previous: null});
  const [showDelete, setshowDelete] = useState(false);
  const [selRow, setSelRow] = useState(null)



  let breadcrumbData = propsState && propsState.filterVal ? [
    {
      title : 'Dashboard',
      href : '/dashboard'
    },{
      title : 'Vendor',
      href : '#'
    }
  ] : [
    {
      title : 'Vendor',
      href : '#'
    }
  ]
    let statusMap = {
      'draft' : 'Draft',
      waiting_for_approval : 'Waiting for Approval',
      approved : 'Approved',
      publish : 'Publish',
      rejected : 'Rejected',
      registered : 'Registered'
    }
  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const setLoader = (val) => {
    setloading(val);setoverlay(val);
  }

  const manipulateData = (res,pageNum) => {
    let count =  Math.ceil(res.data.count/20)
    setpage({page:pageNum || page.page,count:count,total:res.data.count, next:res.data.next,previous:res.data.previous});
    settableData(res.data.results);
    setLoader(false);
  }


  const handlePagination = (e, newPage) => {
    setLoader(true);
    let url = config.service+'/masters/vendor/?&page='+newPage;
    if(!empty(searchTerm)) url += '&search='+searchTerm;
    get(url,tokenKey).then(res=>{
      manipulateData(res,newPage);
      window.scrollTo({
        top: 0,
        behavior: 'instant',
      })
    }).catch(err => {
      setLoader(false);
    })
  }

  const handleTablePagination = (type,{page}) => {
    if(type === 'pagination'){
      setLoader(true);
      let url = config.service+'/masters/vendor/?&page='+page;
      if(!empty(searchTerm)) url += '&search='+searchTerm;
      get(url,tokenKey).then(res=>{
        manipulateData(res,page);
        window.scrollTo({
          top: 0,
          behavior: 'instant',
        })
      }).catch(err => {
        setLoader(false);
      })
    }
  }


  const handleClose = () =>{ setshowDelete(false);setSelRow(null)};

  const deleteRow = () => {
    setshowDelete(false)
    setLoader(true);
    axios.delete(config.service+'/masters/vendor/'+selRow.id,tokenKey).then(resp=>{
      toast.success('Deleted successfully', toastAttr);
      get(config.service+'/masters/vendor/',tokenKey).then(res=>{
        manipulateData(res,1)
    })
    }).catch(err=>{
      if(err.response.status === 401) window.location.href = "/"
      toast.error('Deletion Failed', toastAttr);
      setLoader(false);
    })
  }

  const renderForm = (row) => {
      if(row) history.push({
          pathname:"/vendorForm",
          state : {
            id: row.id,
          editMode:true,
          // tabValue:'vendor'
          }
      })
    else history.push("/vendorForm")
  }


  const setFilter = (keyword) =>{
    let url = currentURL ? currentURL : config.service+'/masters/vendor/';
    if(url.includes(keyword)) url = config.service+'/masters/vendor/';
    else url = config.service+'/masters/vendor/?&'+keyword;
    setLoader(true);
    get(url,tokenKey).then(res=>{
      manipulateData(res,1)
      handleToggleCollapse();
      setCurrentURL(url)
      setLoader(false);
    }).catch(err=>{
      if(err.response.status === 401) window.location.href = "/"
      toast.error('Search Failed', toastAttr);
      setLoader(false);
    })
  }

  const renderOnSearch = searchTerm => {
    setLoader(true);
    get(config.service+'/masters/vendor/?&search='+searchTerm,tokenKey).then(res=>{
      setsearchTerm(searchTerm)
      manipulateData(res,1)
    }).catch(err=>{
      if(err.response.status === 401) window.location.href = "/"
      setLoader(false);
      toast.error('Search Failed', toastAttr);
    })
  }

  const mapFilterVal = val => {
    let param = '';
    switch(val) {
      case 'kycPending' : param = 'is_kyc_pending=true'; break;
      case 'photosPending' : param = 'is_images_pending=true'; break;
      case 'draft' : param = 'current_status=draft'; break;
    }
    return param;
  }

  useEffect(() => {
    let url = config.service+'/masters/vendor/';
    if(propsState && propsState.filterVal){
      let param = mapFilterVal(propsState.filterVal)
      url = config.service+'/masters/vendor/?&'+param;
    }
    get(url,tokenKey).then(res=>{
      manipulateData(res,1)
    }).catch(err=>{
      if(err.response.status === 401) window.location.href = "/"
    })
  }, []);

  const cols = [
    {
      dataField : 'vendor_name',
      text:'Vendor Name',
      headerStyle : () => { return{width : '350px'}}
    },
    {
      dataField : 'owner_name',
      text:'Owner Name',
      headerStyle : () => { return{width : '300px'}}
    },
    {
      dataField : 'owner_phone',
      text:'Owner Mobile',
      headerStyle : () => { return{width : '130px'}}
    },
    {
      dataField : 'category_name',
      text:'Category',
      headerStyle : ()=>{ return{width :'130px'}}
    },
    {
      dataField : 'id',
      text:'Actions',
      headerStyle : () => { return{width : '80px'}},
      events : {
        OnClick : e=>{return e.stopPropagation(); }
      },
      formatter:(cell,row)=>{
        return(
        <div className='tableActions'>
          <React.Fragment>
            <Link to={{pathname:"/vendorForm",state:{id:row.id, editMode:true}}}>
              <FaEdit title='Edit' className='color-green' />
            </Link>
            <HiOutlineTrash className='themeColor actionIcon'onClick={()=>{setshowDelete(true);setSelRow(row);}}/>
          </React.Fragment>
        </div>)
      }
    }
  ];

  const rowEvents = {
    onClick : (e,row) =>{
      history.push({
        pathname:"/vendorForm",
        state : {
          id: row.id,
        editMode:true,
        }
    })
    }
  }

  let options = paginationFactory({
    page: page.page,
    sizePerPage: 20,
    paginationSize:3,
    hideSizePerPage:true,
    hidePageListOnlyOnePage : true,
    totalSize:page.total
  })

  return (
  <Container className="vendor">
    <Row>
  <Col md={12} className='contBody'>
    <Card>
      <CardBody>
        <div className="card__title pageNav">
          <div className="bold-text">
          <Breadcrumbs data={breadcrumbData} />
          </div>
        </div>
        <Row className='formBtnsDiv mar-b-10'>
  <Col>
    <Button variant='primary' onClick={e=>renderForm()}>Create</Button>
  </Col>
  {/* <Col style={{textAlign:'right'}}>
  <div className="filterData">
    <Button variant='light' onClick={handleToggleCollapse}><BiFilterAlt /> Filter</Button> 
      <Collapse isOpen={isCollapsed} className="filterWrap">
        <div className="filterMenu">
          <div onClick={e=>{setFilter('vendor_name')}}>Vendor Name</div>
          <div onClick={e=>{setFilter('current_status')}}>Status</div>
        </div>
      </Collapse>
    </div>
  </Col> */}
  <Col md={4}>
    <Search callbackFn={e=>renderOnSearch(e)} />
  </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className='viewIcons'>
              <span><FaListUl title='Card View' onClick={()=>{setview('list')}} /></span>
              <span><FaTable title='List View' onClick={()=>{setview('table')}} /></span>
            </div>
          </Col>
        </Row>
        <Row className='vendorList'>
          <Loader loading={loading} overlay={overlay}>
            {view === 'list' ? 
            tableData.length>0 ? tableData.map(row=>{
                return (
                    <Col md={5} className='divCont'>
                      <Row>
                        <Col md={2}>
                          <div className='child' onClick={e=>renderForm(row)}>
                            <FaUserAlt className='vendorImg' />
                          </div>
                        </Col>
                        <Col md={7}>
                          <div className='child' style={{fontSize:'14px'}} onClick={e=>renderForm(row)}>
                              <div className='header'> {row.vendor_name}</div>
                              <div>Status : {statusMap[row.current_status]}</div>
                              <div>Owner Name : {row.owner_name}</div>
                              <div>Owner Phone : {row.owner_phone}</div>          
                              <div>Owner Email : {row.owner_email}</div>
                              <div>Category : {row.category_name}</div>      
                          </div>  
                        </Col>
                        <Col md={3}>
                          <div className='child' style={{margin:'15px 0'}}>
                              <HiPencilAlt className='color-green actionIcon' onClick={e=> renderForm(row)}/>
                              <HiOutlineTrash className='themeColor actionIcon' onClick={()=>{setshowDelete(true);setSelRow(row);}} />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                )
            }) : <div className='textCenter' style={{padding:'25px'}}>No details found</div>
            :  <BootstrapTable remote  bootstrap4  wrapperClasses='table-responsive  pad-20' data={tableData} columns={cols} 
    keyField='id' containerStyle={{width:'100%'}} hover noDataIndication='No data found' rowEvents={rowEvents}
    pagination={options} onTableChange={handleTablePagination} /> }
    {view === 'list' && !empty(tableData) ? <Row className='common-pagination'>
        <Col md={6} />
        <Col md={6}>
        <Pagination className='paginate' count={page.count} page={page.page} onChange={handlePagination} color='primary' />
        </Col>
      </Row> : ''}
            <Col md={6}></Col>
            </Loader>
        </Row>
      </CardBody>
    </Card>
  </Col>
  </Row>
  <Dialog open={showDelete} onClose={handleClose} maxWidth='md'>
        <DialogTitle>Confirm Deletion <FaTimes onClick={()=>setshowDelete(false)} className='popup-close' title='close' /></DialogTitle>
        <DialogContent>
          <div style={{paddingRight:'30px'}}>Are you sure to delete ?</div>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteRow}>Delete</Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
  <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
  </Container>
);
};

export default Vendor;
