import React,{useState,useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import {TextField, Dialog, DialogActions,DialogContent, DialogContentText,DialogTitle} from '@mui/material'
import {FaDownload} from 'react-icons/fa';

const ViewQRCode = (props) => {
  const downloadImage = () => {
    fetch(props.data.qr_image)
        .then(resp => resp.blob())
        .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = props.data.vendor_name+'.png';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        })
        .catch(() => console.log('Cannot download QR code'));
  }
  return (
    <>
    <Dialog open={true} onClose={props.handleClose} maxWidth='md'>
        <DialogTitle>QR Code - {props.data.qr_code}<span style={{float:'right'}}><FaDownload title='Click to Download' className='downloadQRCode' onClick={downloadImage} /></span></DialogTitle>
        <DialogContent>
        <div className='viewQRCode'>
          <div>
            <img src={props.data.qr_image} alt = '' className='vendorQRcode' />
          </div>
        </div>
        </DialogContent>
      </Dialog>

        </>
);
};


export default ViewQRCode;
