import React,{useState,useEffect} from 'react';
import { Col, Container, Row, Card,CardBody, Modal,ModalHeader,ModalBody,ModalFooter } from 'reactstrap';
import { Button, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import axios,{post,get,patch} from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import config from '../../../config';
import Loader from '../../Helper/Loader'
import empty from 'is-empty'
import OTPInput, { ResendOTP } from "otp-input-react";
import Topbar from '../../Layout/topbar/Topbar';
import PropTypes from 'prop-types';
import classNames from 'classnames';


const OTPSignIn = ({mobileSidebarVisibility,sidebarVisibility}) => {
  let history = useHistory();
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false) 
  const [OTP, setOTP] = useState("");
  const [loading, setloading] = useState(false);
  const [overlay, setoverlay] = useState(false);

  let toastAttr = {
    theme: "colored",
    position: "top-center",
    autoClose: 1000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    };
  const [tab, setTab] = useState({
      details:true,
  });
    const [tabData, setTabData] = useState([
        {
            name:'Vendor Details',
            id:'details',
            show:true
        }
    ]);

    const toggleModal = () => {
        setShowModal(!showModal);
        setOTP('')
    }

    const handleInput = (keyName,value) => {
        setForm({
            ...form,
            [keyName] : value
        })
        if (keyName && !!errors[keyName]) setErrors({
            ...errors,
            [keyName]: null
          })
    }
    const handleSelect = (keyName,setFn,e) =>{
        setFn(e);
        setForm({
            ...form,
            [keyName] : e.value
        })
        if (keyName && !!errors[keyName]) setErrors({
            ...errors,
            [keyName]: null
          })
    }


  const redirectToLogin = () => {
      history.push("/")
  }

  const findErrors = () => {
    const { first_name,mobile } = form
    const newErrors = {}
    if ( empty(first_name) ) newErrors.first_name = 'This is a required field';
    if ( empty(mobile) ) newErrors.mobile = 'This is a required field';

    return newErrors;
   }

  const setLoader = (val) => {
    setloading(val);setoverlay(val);
  }

  const genOTP = () => {
      if(form.mobile){
        let data = {
            // username : form.username,
            mobile : form.mobile,
            user_type : 'vendor'
        }
      post(config.service+'/sms/generateOtp/',data).then(res=>{
          setShowModal(true);
        }).catch(err=>{
          toast.error('OTP generation failed', toastAttr);
        });
      } else 
      toast.error('Enter mobile number', toastAttr);
  }

  const verifyOTP = () => {
    let data = {
        // username : form.username,
        mobile : form.mobile,
        user_type : 'vendor',
        otp:OTP,
    }
    post(config.service+'/sms/verifyOtp/',data).then(res=>{
        toggleModal();
        sessionStorage.setItem('tokenKey',res.data.key)
        sessionStorage.setItem('userName',form.mobile)
        get(config.service+'/accounts/user_role/',{headers:{Authorization:'Token '+res.data.key}}).then(res=>{
            sessionStorage.setItem('permission',JSON.stringify(res.data))
            history.push('/dashboard')                     
        })                                            
      }).catch(err=>{
        toast.error('OTP verification failed', toastAttr);
      });
  }

const renderButton = (buttonProps) => {
    return <button className='btn btn-primary' {...buttonProps}>Resend OTP</button>;
};
const renderTime = (remainingTime) => {
    return <span>{remainingTime} seconds remaining</span>;
};

const layoutClass = classNames({
    layout: true,
    'layout--collapse': true,
  });

  return (
      <>
    <div className={layoutClass}>
    <Topbar hideLogout={true}
      changeMobileSidebarVisibility={mobileSidebarVisibility}
      changeSidebarVisibility={sidebarVisibility}
    />
    </div>
  <Container className="vendor">
    <Row>
  <Col md={12} className='contBody'>
    <Card>
      <CardBody className='theme-light'>
        <div className="card__title pageNav">
          <div className="bold-text themeColor pad-l-10">Sign In with OTP</div>
        </div>
        <Row className='formBtnsDiv'>
            <Col md={12}>
                    <Button variant='primary' onClick={e=>genOTP()}>Generate OTP</Button>
                    <Button variant='secondary' onClick={e=>{redirectToLogin()}}>Cancel</Button>
                    {/* <Button variant='primary' onClick={e=>genOTP()}>Generate OTP</Button> */}
            </Col>
        </Row>
        <Row className='formBtnsDiv'>
            <Col md={12} style={{margin:'5px 0'}}>
            </Col>
        </Row>
        <Row className='formDiv'>
            <Col md={12}>
                <nav>
                    <div className='nav nav-tabs' id='nav-tab' role='tablist'>
                        <div className='tabList'>
                            {tabData.map((tab,index)=>{
                                let disCls = tab.disable;
                                return tab.show ? (
                                    <a className={`nav-item nav-link active`} 
                                    id={tab.id} name={index} data-toggle='tab' role='tab' aria-controls='nav-details' aria-selected='true'>
                                        {tab.name}
                                    </a>
                                ) : '';
                            })}
                        </div>
                    </div>
                </nav>
                <div className='tabContent'>
                    <Form className="form defaultForm" >
                    <Loader loading={loading} overlay={overlay}>
                        {tab.details ? 
                        <Row><Col md={12}>
                            {/* <fieldset className="border p-2 fieldsetCls" style={{minHeight:'490px'}}>
                                <legend className="w-auto"> Details</legend> */}
                                    <Row>
                                        <Col md={6}>
                                            {/* <Row>
                                                <Col md={4}>
                                                    <Form.Label>Username</Form.Label>
                                                </Col>
                                                <Col md={7}>
                                                <Form.Control type='text' name="username" value={form.username} onChange={e=> handleInput('username',e.target.value)} isInvalid={!! errors.username} />
                                                <Form.Control.Feedback type='invalid'>
                                                    { errors.username }
                                                </Form.Control.Feedback>
                                                </Col>
                                            </Row> */}
                                            <Row>
                                                <Col md={4}>
                                                    <Form.Label>Mobile</Form.Label>
                                                </Col>
                                                <Col md={7}>
                                                <Form.Control type='number' name="mobile" value={form.mobile} onChange={e=> handleInput('mobile',e.target.value)} isInvalid={!! errors.mobile} />
                                                <Form.Control.Feedback type='invalid'>
                                                    { errors.mobile }
                                                </Form.Control.Feedback>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                            {/* </fieldset> */}
                        </Col></Row>
                        : ''}
                    </Loader>
                    </Form>
                </div>
            </Col>
        </Row>
        <Modal toggle={e=>{toggleModal()}} isOpen={showModal} className='otpModal' >
          <ModalHeader
            toggle={e=>{toggleModal()}}
          >
            Verify OTP
          </ModalHeader>
          <ModalBody>
              <div className='otpCont'>
                <OTPInput value={OTP} onChange={setOTP} autoFocus OTPLength={6} otpType="number" secure />
                <ResendOTP className="otpResend" renderButton={renderButton} renderTime={renderTime} onResendClick={() => console.log("Resend clicked")} />
              </div>         
         </ModalBody>
         <ModalFooter>
            <Button variant='primary' onClick={e=>{verifyOTP()}}>Verify</Button> 
         </ModalFooter>
        </Modal>
      </CardBody>
    </Card>
  </Col>
  </Row>
  <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
  </Container></>
);
};

OTPSignIn.propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
  };

export default OTPSignIn;
