import React,{useState,useEffect} from 'react';
import Switch from "react-switch";


const CustomSwitch = (props) => {

    const handleChange = (checked) =>{
        if(props.data) props.handleChange(props.name,checked, props.data)
        else props.handleChange(props.name,checked)
    }
    return(
        <Switch disabled={props.disabled} className={props.className}
        onChange={(checked) => { handleChange(checked)}} height={22} width={42} handleDiameter={17}
        checked={props.value}  uncheckedIcon={false} checkedIcon={false}
        offColor={'#dddd '} onColor={'#00da06'}
        />
    )
}
export default CustomSwitch;