import React, {useState,useEffect} from 'react';
import { Card, CardBody, Col ,Row,Container} from 'reactstrap';
import { Button, Form } from 'react-bootstrap';
import Status from './views/status';
import Booking from './views/booking';
import {post,get} from 'axios';
import config from '../../../config'
import Breadcrumbs from '../../Helper/Breadcrumbs'
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Stack from '@mui/material/Stack';

const Dashboard = (props) => {
  let tokenKey = {headers:{Authorization:'Token '+sessionStorage.getItem('tokenKey')}};
  const [indexVal, setIndexVal] = useState(0);
  const [data, setdata] = useState(null);
  const [loading, setloading] = useState(true);
  const [date, setdate] = useState(new Date() || null);

  let breadcrumbData = [
    {
      title : 'Dashboard',
      href : '#'
    }
  ]
  
  const [tab, setTab] = useState({
    status:true,
    booking:false
});
  const [tabData, setTabData] = useState([
    {
        name:'Status',
        id:'status',
        show:true
    },
    {
      name:'Bookings',
      id:'booking',
      show:true
  }
]);

const setLoader = (val) => {
  setloading(val);
}

const handleTab = (e,index)=>{
  let tabValue = e.target.id;
  setIndexVal(index);
  setTab({
      status:false,
      booking:false,
      [tabValue] : true
        })
      };

  useEffect(() => {
    get(config.service+'/masters/dashboard/',tokenKey).then(res=>{
      setdata(res.data);
      setLoader(false);
    }).catch(err=>{
      if(err.response.status === 401) window.location.href = '/'
    })
  },[]);

  return(
  <Container className="dashboard">
  <Row>
  <Col md={12} className='contBody'>
    <Card>
      <CardBody>
        <div className="card__title">
          <h4 className="bold-text">
          <Breadcrumbs data={breadcrumbData} />
          </h4>
          <Row className='formDiv dbCont'>
            <Col md={12}>
              <Row>
                <Col md={9}>
                <nav>
                    <div className='nav nav-tabs' id='nav-tab' role='tablist'>
                        <div className='tabList'>
                            {tabData.map((tab,index)=>{
                                let disCls = tab.disable;
                                return tab.show ? (
                                    <a className={`nav-item nav-link ${indexVal === index ? 'active' : ''}`} 
                                    id={tab.id} name={index} data-toggle='tab' role='tab' aria-controls='nav-details' aria-selected='true'
                                    onClick={e=> handleTab(e,index)} >
                                        {tab.name}
                                    </a>
                                ) : '';
                            })}
                        </div>
                    </div>
                </nav>
                </Col>
                {tab.status ?
                <Col md={3} style={{paddingTop:'10px'}}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Stack spacing={2}>
                        <DatePicker
                        views={['year', 'month']}
                        label="Select a Month"
                        minDate={new Date('2015-01-01')}
                        maxDate={new Date()}
                        value={date}
                        onChange={(newValue) => {
                            setdate(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} helperText={null} />}
                        />
                        </Stack>
                    </LocalizationProvider>
                </Col> : ''}
              </Row>
                <div className='tabContent'>
                    <Form className="form defaultForm" >
                        {tab.status && data ? <Status  loading={loading} date={date}  /> : ''}
                        {tab.booking && data ? <Booking data={data}  loading={loading} /> : ''}
                    </Form>
                </div>
            </Col>
        </Row>
        </div>
      </CardBody>
    </Card>
  </Col>
  </Row>
  </Container>
)};

export default Dashboard;
